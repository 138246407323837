import React, { useState, Fragment} from "react";
//Imports material-ui
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//Servicios
import UsuarioServices from "../../services/user";

//Componente
import Alert from '../../components/Alert'

export default function UsuarioModalRefresh(props) {

    const [openAlertError, setOpenAlertError] = useState(false);

    let usuarioResponse = null;
    let usuarioError = null;

    async function refreshPwd() {
        const tokenActual = window.localStorage.getItem('loggedDashboard').slice(1,-1)
        try{
            usuarioResponse =  await UsuarioServices.refreshUsuario(tokenActual, props.id);
        }catch(error){
            usuarioError = (error.message)
        }

        if(usuarioError==null){
            props.setUsuarioFinalizar(usuarioResponse.usuario);
            props.setPwdFinalizar(usuarioResponse.passwordTemporal);
            props.setOpenFinalizar(true);
            handleClose()
        }else{
            setOpenAlertError(true)
        }
    }

    
    const handleClose = () => {
        props.setOpenRefresh(false);
    };
    
    
    return(
        <Fragment>
            <DialogTitle>Recuperación de contraseña</DialogTitle>
            <DialogContent>
                ¿Está seguro que desea recuperar la contraseña?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={refreshPwd} variant="contained" >Aceptar</Button>
            </DialogActions>
            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje="Error al Recuperar" severity="error"/>
        </Fragment>
    );
}