import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TextField from '@mui/material/TextField';

import AutoComplete from '../../components/AutoComplete';

const MercaderiaDocumentoRelacionado = ({
    documento,
    onDocumentoChange,
    tiposDocumento,
    indexDocumento,
    onDelete,
    isDisabled
}) => {
    const [documentoState, setDocumentoState] = useState(documento)

    const [inputTipoDocumento, setInputTipoDocumento] = useState('')

    const handleFieldChange = (values) => {
        setDocumentoState(prevState => ({
            ...prevState,
            ...values
        }))

        onDocumentoChange({
            ...documentoState,
            ...values
        })
    }

    const handleDelete = () => {
        onDelete(documentoState.id);
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id

    function handleOptionLabelNombre (objeto) {
        return objeto.nombre
    }

    const changeStateOnlyIfDecimalNumber = (newValue, stateFunction) => {
        if(/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if(/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfNumberOrLetter = (newValue, stateFunction) => {
        if(/^[a-zA-Z0-9]*$/.test(newValue)) {
            stateFunction(newValue.toUpperCase())
        }
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size, disabled) => {
        return (
            <AutoComplete
                id = {id}
                value = {value || null}
                onChange = {onChange}
                inputValue = {inputValue || ''}
                onInputChange = {onInputChange}
                options = {options}
                getOptionLabel = {getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width = {width}
                label = {label}
                size = {size}
                disabled={disabled}
            />
        )
    }

    const handleTipoDocumentoSelectedChange = (event, newValue) => {
        handleFieldChange({
            tipoDocumento: newValue ? newValue : null
        })
    }

    const handleInputTipoDocumentoChange = (event, newValue) => {
        setInputTipoDocumento(newValue)
    }

    const renderTipoDocumento = () => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `tipoDocumento${documentoState.id}-tipoDocumentoBox`,
                    documentoState.tipoDocumento,
                    handleTipoDocumentoSelectedChange,
                    inputTipoDocumento,
                    handleInputTipoDocumentoChange,
                    tiposDocumento,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Tipo Documento',
                    'small',
                    isDisabled
                )}
            </Grid>
        )
    }

    const handleSerieChange = (newValue) => {
        handleFieldChange({
            serie: newValue
        })
    }

    const renderSerieTextField = () => {
        return (
            <Grid item xs={4.5} sm={2.25} md={2.25} lg={2.25} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`documento${documentoState.id}-${documentoState.serie}-serieTextField`}
                    label="Serie"
                    variant="outlined"
                    value={documentoState.serie}
                    onChange={(event) => changeStateOnlyIfNumberOrLetter(event.target.value, handleSerieChange)}
                    size='small'
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                        maxLength: 4
                    }}
                >
                </TextField>
            </Grid>
        )
    }

    const handleNumeroChange = (newValue) => {
        handleFieldChange({
            numero: newValue
        })
    }

    const renderNumeroTextField = () => {
        return (
            <Grid item xs={6} sm={2.60} md={2.75} lg={2.75} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`documento${documentoState.id}-${documentoState.numero}-numeroTextField`}
                    label="Número"
                    variant="outlined"
                    value={documentoState.numero}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, handleNumeroChange)}
                    size='small'
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                        maxLength: 6
                    }}
                >
                </TextField>
            </Grid>
        )
    }

    return (
        <Grid style={{padding: '0px', margin: '0px 0px 0px 0px'}} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <Divider textAlign="left">
                    Documento {indexDocumento}
                </Divider>
            </Grid>
            {renderTipoDocumento()}
            {renderSerieTextField()}
            {renderNumeroTextField()}
            <Grid item xs={1.5} sm={1.15} md={1} lg={1} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <IconButton aria-label="add-address" size="medium" color="error" onClick={handleDelete} disabled={isDisabled}>
                    <RemoveCircleOutlineIcon fontSize="medium" />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default MercaderiaDocumentoRelacionado