import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import CustomizeSnackBar from '../../components/Snackbar';

import AuditoriaService from '../../services/auditorias';

const Auditoria = (props) => {
    const [auditoriaData, setAuditoriaData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    
    const [usuarioFilter, setUsuarioFilter] = useState('')
    const [fechaFilter, setFechaFilter] = useState('')

    const [activateFilters, setActivateFilters] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')


    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1,-1)
        //setTokenActual(loggedDashboardJSON)
        findAllClientes(
            loggedDashboardJSON,
            currentPage-1,
            usuarioFilter,
            fechaFilter,
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, activateFilters]);

    const findAllClientes = async (token, page, usuarioFilter, fechaFilter) => {
        try {
            const responseData = await AuditoriaService.getAuditorias(token, page, usuarioFilter, fechaFilter);
            setAuditoriaData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    /*const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });*/

    const changeStateOnlyIfDate = (newValue, stateFunction) => {
        const formatoFecha = /^\d{0,4}(-\d{0,2}){0,2}$/
        if(formatoFecha.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }

    const renderFechaTextFilter = () => {
        return (
            <Grid item xs={9.5} sm={3.25} md={4.5} lg={4.5} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    id="fecha-textfield"
                    label="Fecha (aaaa-mm-dd)"
                    variant="outlined"
                    value={fechaFilter}
                    onChange={(event) => changeStateOnlyIfDate(event.target.value, setFechaFilter)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderUsuarioFilter = () => {
        return (
            <Grid item xs={12} sm={6.25} md={6.0} lg={6.0} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    id="usuario-filter"
                    label="Usuario"
                    variant="outlined"
                    value={usuarioFilter}
                    onChange={(event) => setUsuarioFilter(event.target.value)}
                    size='small'
                    autoComplete='off'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        //if(tipoUnidadSelected !== null || marcaUnidadSelected !== null || placaFilter !== '' || empleadoSelected !== null) {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
        //}
    }

    const handleClearFilters = () => {
        setUsuarioFilter('')
        setFechaFilter('')
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
						<IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
					</Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
						<IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
					</Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderUsuarioFilter()}
                        {renderFechaTextFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnDocIdent', align: 'center', label: 'Usuario', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Módulo', minWidth: 75, format: 'string' },
        { id: 'columnNombres', align: 'center', label: 'Acción', minWidth: 75, format: 'string' },
        { id: 'columnCelular', align: 'center', label: 'Dirección IP', minWidth: 75, format: 'string' },
        { id: 'columnAcciones', align: 'center', label: 'Fecha y Hora', minWidth: 75, format: 'string' }
    ];

    /*const handleEditTableButton = (clienteId) => {
        setSelectedClienteId(clienteId)
        setTitleDialog('Actualizar Cliente')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(false)
    }*/

    /*const handleDeleteTableButton = (clienteId) => {
        setSelectedClienteId(clienteId)
        setOpenDeleteDialog(true)
    }*/

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {renderPagination()}
                </Paper>
            </Grid>
        )
    }

    /*const renderActionTableButtons = (cliente) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton aria-label="edit" color="warning" onClick={() => handleEditTableButton(cliente.nroDocumento)}><EditIcon fontSize="medium" /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteTableButton(cliente.nroDocumento)}><DeleteIcon fontSize="medium" /></IconButton>
                    </Grid>
                </ThemeProvider>
            </Grid>
        )
    }*/

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(auditoriaData === null) ? 
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (auditoriaData.map(auditoria => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${auditoria.id}`}>
                                            <TableCell align='center'>
                                                {auditoria.usuario}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {auditoria.modulo}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {auditoria.accion}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {auditoria.usuarioIP || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {auditoria.fecha || "---"}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                <Table>
                    {(auditoriaData === null) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (auditoriaData.map(auditoria => (
                            <TableBody key={`${auditoria.id}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: '0 0 105px'}}>Usuario:</div>
                                            <div style={{flex: '1', textAlign: 'left'}}>{auditoria.usuario}</div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: '0 0 105px'}}>Módulo:</div>
                                            <div style={{flex: '1', textAlign: 'left'}}>{auditoria.modulo}</div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: '0 0 105px'}}>Acción:</div>
                                            <div style={{flex: '1', textAlign: 'left'}}>{auditoria.accion}</div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: '0 0 105px'}}>Dirección IP:</div>
                                            <div style={{flex: '1', textAlign: 'left'}}>{auditoria.usuarioIP || "---"}</div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: '0 0 105px'}}>Fecha y Hora:</div>
                                            <div style={{flex: '1', textAlign: 'left'}}>{auditoria.fecha || "---"}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
    );
};

export default Auditoria;