import React, { useState, useEffect } from "react";
//Imports material-ui
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';

import RolServices from "../../services/roles";


//Servicios
import { SubModulo1Service } from "../../services/submodulo1";

//Vistas
import RolesAddUpdate from "./RolesAddUpdate";
import RolesModalEliminar from "./RolesModalEliminar";
import RolesViewDetails from "./RolesViewDetails";
import RolesCreateUpdate from "./RolesCreateUpdate";

//componentes
import Alert from '../../components/Alert'

import TextField from '@mui/material/TextField';

export default function Roles(props) {
    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Roles;

    const [tableBody, setTableBody] = useState();
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [titleModal, setTitleModal] = useState("");
    const [componentTableResponsive, setComponentTableResponsive] = useState("");
    const [openAlertOk, setOpenAlertOk] = useState(false);
    const [msjAlertExitoso, setMsjAlertExitoso] = useState('');
    const [severityAlert, setSeverityAlert] = useState('info');

    const [idSelectedRol, setIdSelectedRol] = useState(0);

    const [cantPaginas, setCantPaginas] = useState(0)
    const [page, setPage] = useState(1);

    const [tokenActual, setTokenActual] = useState('');

    const [nombreRolFiltro, setNombreRolFiltro] = useState('');

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            cart: {
                main: '#673ab7',
            }
        },
    });

    const columns = [
        { id: 'columnNombre', align: 'center', label: 'Nombre', minWidth: 170, format: 'string' },
        { id: 'columnDescripción', align: 'center', label: 'Descripción', minWidth: 170, format: 'string' },
        { id: 'columnModulos', align: 'center', label: 'Módulos', minWidth: 170, format: 'string' },
    ];

    //const tokenActual = "eyJhbGciOiJIUzI1NiJ9.eyJzdWJqZWN0IjoiY2xhcmtpcnZpbmcyMiIsImF1dGhvcml0aWVzIjoiW3tcImF1dGhvcml0eVwiOlwiUk9MRV9VU1VBUklPXCJ9XSIsImlhdCI6MTcxMDU0MTg2OCwiZXhwIjoxNzExNzYxNDY4fQ.K7_4urLSGVtnSfJoPN3QF_Y9LWoFlJzCQl0eDGyur2I";

    let rolesResponse = null;
    let rolesError = null;

    const findAllRoles = async (token, page, nombre) => {
        try {
            const responseData = await RolServices.getRoles(token, page, nombre);
            rolesResponse = (responseData);
        }
        catch (error) {
            rolesError = (error.message);
        }
    };

    async function reloadAllEntidad(token, nroPag, nombre) {
        await findAllRoles(token, nroPag, nombre);
        if (rolesError === null) {
            let rowsEntidad = rolesResponse.content;
            let cantPaginas = rolesResponse.totalPages;
            setCantPaginas(cantPaginas);

            setTableBody(
                <TableBody>
                    {rowsEntidad.map((row) => {
                        //cantPaginas = Math.ceil(row.total_elements / 10);
                        //setCantPaginas(cantPaginas)
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                <TableCell align="center">
                                    {row.nombre}
                                </TableCell>
                                <TableCell align="center">
                                    {row.descripcion}
                                </TableCell>
                                <TableCell align="center">
                                    Ver detalle
                                    <IconButton aria-label="view-details" color="success" value={row.id} onClick={() => handleClickOpenDetails(row.id)}><VisibilityIcon fontSize="medium" /></IconButton>
                                </TableCell>
                                <TableCell key="options" align="center">
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                                        <ThemeProvider theme={theme}>
                                            {
                                                permisos.includes(3) ?
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <IconButton aria-label="edit" color="warning" value={row.id} onClick={() => handleClickOpenUpdate(row.id)}><EditIcon fontSize="medium" /></IconButton>
                                                    </Grid> : null
                                            }
                                            {
                                                permisos.includes(4) ?
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <IconButton aria-label="delete" color="error" value={row.id} onClick={() => handleClickOpenDelete(row.id)}><DeleteIcon fontSize="medium" /></IconButton>
                                                    </Grid> : null
                                            }

                                        </ThemeProvider>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            );


            setComponentTableResponsive(
                <Table>
                    {rowsEntidad.map((row) => {
                        //cantPaginas = Math.ceil(row.total_elements / 10);
                        //setCantPaginas(cantPaginas)
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>
                                    <div>Nombre: {row.nombre}</div>
                                    <div>Descripción: {row.descripcion}</div>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                        <ThemeProvider theme={theme}>
                                            <Grid container style={{ paddingTop: "12px" }}>
                                                <Grid item xs={4} sm={4} md={4}>
                                                    <div style={{ justifyContent: "center", display: "flex" }}>
                                                        <IconButton aria-label="view-details" color="success" value={row.id} onClick={() => handleClickOpenDetails(row.id)}><VisibilityIcon fontSize="medium" /></IconButton>
                                                    </div>
                                                </Grid>
                                                {
                                                    permisos.includes(3) ?
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <IconButton aria-label="edit" color="warning" value={row.id} onClick={() => handleClickOpenUpdate(row.id)}><EditIcon fontSize="medium" /></IconButton>
                                                            </div>
                                                        </Grid> : null
                                                }
                                                {
                                                    permisos.includes(4) ?
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <IconButton aria-label="delete" color="error" value={row.id} onClick={() => handleClickOpenDelete(row.id)}><DeleteIcon fontSize="medium" /></IconButton>
                                                            </div>
                                                        </Grid> : null
                                                }
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </Table>
            )
        }
    }

    const handleClickOpenCreate = () => {
        setTitleModal("Crear Rol")
        setOpenCreate(true);
        setIsCreate(true);
    };

    const handleClickOpenUpdate = (rolId) => {
        setTitleModal("Actualizar Rol")
        setOpenCreate(true);
        setIsCreate(false);
        setIdSelectedRol(rolId);
    };

    const handleClickOpenDetails = (rolId) => {
        setTitleModal("Módulos")
        setOpenDetails(true);
        setIdSelectedRol(rolId);
    };

    const handleClickOpenDelete = (rolId) => {
        setOpenDelete(true);
        setIdSelectedRol(rolId);
    };

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        reloadAllEntidad(loggedDashboardJSON, 0, '')
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }

    }, []);


    async function aplicarFiltro() {
        reloadAllEntidad(tokenActual, 0, nombreRolFiltro)
        setPage(1);
    }

    const clearFiltro = () => {
        setNombreRolFiltro('')
        reloadAllEntidad(tokenActual, 0, '')
        setPage(1);
    }

    const handleChangePagina = (event, value) => {
        reloadAllEntidad(tokenActual, value - 1, nombreRolFiltro)
        setPage(value);
    };


    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12} xm={6} md={6}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }} style={{ padding: "6px" }}>
                        {/*<Grid item xs={12} sm={12} md={12}>
                            Filtros
                        </Grid>*/}
                        <Grid item xs={10} sm={10} md={10} >
                            <FormControl fullWidth>

                                <TextField
                                    autoComplete="off"
                                    margin="dense"
                                    id="nombreRolFiltro"
                                    label="Nombre del rol"
                                    type="text"
                                    variant="standard"
                                    size='small'
                                    fullWidth
                                    value={nombreRolFiltro}
                                    onChange={(e) => setNombreRolFiltro(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} >
                            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                <Grid item>
                                    <IconButton aria-label="aplicar" color="primary" onClick={aplicarFiltro} ><ManageSearchIcon /></IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton aria-label="aplicar" color="primary" onClick={() => clearFiltro()} ><ClearIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TableContainer sx={{ maxHeight: 450 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                                <TableCell key="acciones" align="center" style={{ minWidth: "40" }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {tableBody}
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : (
                        <TableContainer>
                            {componentTableResponsive}
                        </TableContainer>
                    )
                    }
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Pagination
                                count={cantPaginas}
                                page={page}
                                variant="outlined"
                                color="primary"
                                onChange={handleChangePagina}
                            />
                        </Grid>
                    </Grid>
                    <Dialog open={openDetails} onClose={() => setOpenDetails(false)}>
                        <RolesViewDetails
                            title={titleModal}
                            setOpenDetails={setOpenDetails}
                            rolId={idSelectedRol}
                            token={tokenActual}
                        />
                    </Dialog>
                    <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
                        <RolesCreateUpdate
                            title={titleModal}
                            setOpenCreate={setOpenCreate}
                            isCreate={isCreate}
                            rolId={idSelectedRol}
                            token={tokenActual}
                            reloadAllEntidad={reloadAllEntidad}
                            page={page - 1}
                            setOpenAlertOk={setOpenAlertOk}
                            setMsjAlertExitoso={setMsjAlertExitoso}
                            setSeverityAlert={setSeverityAlert}
                        />
                    </Dialog>
                    <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                        <RolesModalEliminar
                            setOpenDelete={setOpenDelete}
                            token={tokenActual}
                            id={idSelectedRol}
                            setOpenAlertOk={setOpenAlertOk}
                            setMsjAlertExitoso={setMsjAlertExitoso}
                            setSeverityAlert={setSeverityAlert}
                            reloadAllEntidad={reloadAllEntidad} />
                    </Dialog>

                    <Alert openAlert={openAlertOk} setOpenAlert={setOpenAlertOk} mensaje={msjAlertExitoso} severity={severityAlert} />
                </Paper>
            </Grid>
            {
                permisos.includes(2) ?
                    <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: "6px" }}>
                        <Grid item>
                            <Button color="success" onClick={handleClickOpenCreate} variant="contained">Agregar Rol</Button>
                        </Grid>
                    </Grid> : null
            }

        </Grid>
    );
}