const API_BASE_URL = `${process.env.REACT_APP_BACKEND_SEGURIDAD_URL}/usuarios`;

const manageResponse = async (response) => {
    const statusCode = response.status;
    if(statusCode === 200 ) 
        return await response.json();
    else if(statusCode === 204)
        return null;
    else if(statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if(statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la acción`);
    else
        throw new Error(`Error al obtener datos, contacte al administrador - ${statusCode}`);
}

const UsuarioServices = {
    getUsuarios: async (token, page, usuario, rolId, activo) => {
        try {
            const response = await fetch(`${API_BASE_URL}?page=${page}&usuario=${usuario}&rolId=${rolId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getUsuariosSimple: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL}/simple`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    deleteUsuario: async (token, usuarioId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/eliminar/${usuarioId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    
    refreshUsuario: async (token, usuarioId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/restablecer-pwd/${usuarioId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    
    createUsuario: async (token, dataCreate) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataCreate)
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    
    updateUsuario: async (token, dataCreate) => {
        try {
            const response = await fetch(`${API_BASE_URL}/actualizar`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataCreate)
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getModulos: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL}/modulos`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    updateTempPass: async (token, tempAndNewPasswords) => {
        try {
            const response = await fetch(`${API_BASE_URL}/actualizar-pwd`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(tempAndNewPasswords)
            });

            return response

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    }
};

export default UsuarioServices;