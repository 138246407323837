import React, { useState, useEffect, Fragment } from "react";
//Imports material-ui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

//Servicios
import { SubModulo1Service } from "../../services/submodulo1";

//componente
import Alert from '../../components/Alert'
export default function SubModulo1AddUpdate(props) {

    const server = new SubModulo1Service();

    const [unidadMedida, setUnidadMedida] = useState('');
    const [menuItemUnidadMedida, setMenuItemUnidadMedida] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [openAlertError, setOpenAlertError] = useState(false);
    const [msjError, setMsjError] = useState('');
    const [isActive, setIsActive] = useState(false);

    const handleClose = () => {
        props.setOpenCreate(false);
    };

    const handleChangeUnidadMedida = (event) => {
        setUnidadMedida(event.target.value);
    };


    async function reloadDataConfig() {
        const undidadMedidaResponse = await server.getAllUnidadMedida();
        const arrayUnidadMedida = await undidadMedidaResponse.data;

        setMenuItemUnidadMedida(
            arrayUnidadMedida.map((um) => {
                return (
                    <MenuItem value={um.id}>{um.descripcion}</MenuItem>
                )
            })
        )
    }

    async function createEntidad() {
        var dataFormEntidad = {
            unidadMedida: unidadMedida,
            descripcion: descripcion,
            isActive: isActive
        }
        if (props.isCreate) {
            const entidadResponse = await server.createEntidad(dataFormEntidad);
            setMsjError('Error al insertar')
            if (entidadResponse.status === 200) {
                const entidadResponseData = await entidadResponse.data;
                if (entidadResponseData.status === "ok") {
                    setOpenAlertError(false)
                    props.setMsjAlertExitoso("Insertado correctamente")
                    props.setSeverityAlert('success')
                    props.setOpenAlertOk(true);
                    props.reloadAllEntidad(0, "", "", "")
                    handleClose()
                } else {
                    setOpenAlertError(true);
                }
            } else {
                setOpenAlertError(true);
            }
        } else {
            const entidadResponse = await server.updateEntidad(dataFormEntidad, props.idEntidad);
            setMsjError('Error al actualizar')
            if (entidadResponse.status === 200) {
                const entidadResponseData = await entidadResponse.data;
                if (entidadResponseData.status === "actualizado") {
                    setOpenAlertError(false)
                    props.setMsjAlertExitoso("Actualizado correctamente")
                    props.setSeverityAlert('success')
                    props.setOpenAlertOk(true);
                    props.reloadAllEntidad(0, "", "", "")
                    handleClose()
                } else {
                    setOpenAlertError(true);
                }
            } else {
                setOpenAlertError(true);
            }
        }

    }

    useEffect(() => {
        reloadDataConfig();
    }, [,]);


    return (
        <Fragment>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent style={{ paddingTop: '1vh' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={10} sm={10} md={10}>

                        <FormControl fullWidth>
                            <InputLabel>Unidad de medida</InputLabel>
                            <Select
                                labelId="unidad-medida-select-label"
                                id="unidad-medida-select"
                                value={unidadMedida}
                                label="Unidad de medida"
                                onChange={handleChangeUnidadMedida}
                                fullWidth
                            >
                                {menuItemUnidadMedida}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isActive}
                                    onChange={(e) => setIsActive(e.target.checked)}
                                />
                            }
                            label="Is active" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            margin="dense"
                            id="descripcion"
                            label="Descripcion"
                            type="text"
                            variant="standard"
                            fullWidth
                            value={descripcion}
                            onChange={(e) => setDescripcion(e.target.value.toUpperCase())}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={createEntidad} variant="contained" >Guardar</Button>
            </DialogActions>
            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje={msjError} severity="error" />
        </Fragment>
    );
}