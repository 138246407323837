import TextField from "@mui/material/TextField";

const DateSelector = ({
    id,
    label,
    selectedValue,
    minDate,
    maxDate,
    onChange,
    disabled
}) => {
    /*
    const formatDate=()=>{
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
    
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
    
        const formattedToday =yyyy+'-'+ mm + '-' + dd ;
        return formattedToday;
    }
    */

    const handleDateChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <TextField
            disabled={disabled}
            fullWidth
            id={id}
            label={label}
            type="date"
            value={selectedValue}
            inputProps={{  
                min: minDate,
                max: maxDate
            }}
            onChange={handleDateChange}
            size='small'
            variant="outlined"
            InputLabelProps={{
                shrink: true
            }}
        />
    )
}

export default DateSelector