const API_BASE_URL = `${process.env.REACT_APP_BACKEND_COMERCIAL_URL}/unidades`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if(statusCode === 200) 
        return await response.json();
    else if(statusCode === 204)
        return null;
    else if(statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if(statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if(statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if(statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const UnidadesService = {
    getUnidades: async (token, page, tipoId, marcaId, placa, choferId) => {
        try {
            const tipoIdFilter = tipoId !== null ? `&tipoId=${tipoId}` : ''
            const marcaIdFilter = marcaId !== null ? `&marcaId=${marcaId}` : ''
            const choferIdFilter = choferId !== null ? `&choferId=${choferId}` : ''
            const placaFilter = placa !== '' ? `&placa=${placa}` : ''
            const allfilters = tipoIdFilter + marcaIdFilter + placaFilter + choferIdFilter
            const response = await fetch(`${API_BASE_URL}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getUnidadById: async (token, unidadId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/id/${unidadId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    saveUnidad: async (token, unidad) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(unidad)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    updateUnidad: async (token, unidad) => {
        try {
            const response = await fetch(`${API_BASE_URL}/actualizar`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(unidad)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    deleteUnidadById: async (token, unidadId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/eliminar/${unidadId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getUnidadesEnvios: async (token) => {
        try {

            const response = await fetch(`${API_BASE_URL}/simple`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)

            // return [
            //     {
            //         "id": "3",
            //         "nombre": "ABC-123"
            //     },
            //     {
            //         "id": "4",
            //         "nombre": "CDE-456"
            //     },
            //     {
            //         "id": "7",
            //         "nombre": "C5J-854"
            //     },
            //     {
            //         "id": "8",
            //         "nombre": "CD5-FG4"
            //     },
            //     {
            //         "id": "9",
            //         "nombre": "CD9-FX3"
            //     }
            // ]
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
}

export default UnidadesService;