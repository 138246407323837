import { useState, useEffect, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import GuiaService from '../../services/guia';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';

const GuiaTransportistaDetail = ({
    title,
    token,
    guiaTransportistaId,
    setDialogOpen,
    isSmUp,
    manageAlert
}) => {

    const [guiaTranspData, setGuiaTranspData] = useState(null)
    const [pesoTotal, setPesoTotal] = useState(null)


    const tableColumns = [
        { id: 'columnCantidad', align: 'center', label: 'Cantidad', minWidth: 75, format: 'string' },
        { id: 'columnCantidad', align: 'center', label: 'Unid. med. cant.', minWidth: 75, format: 'string' },
        { id: 'colummnDescripción', align: 'center', label: 'Descripción', minWidth: 75, format: 'string' },
        { id: 'columnPrecioUnit', align: 'center', label: 'Precio Unit.', minWidth: 75, format: 'string' },
        { id: 'columnTotal', align: 'center', label: 'Total', minWidth: 75, format: 'string' }
    ];

    useEffect(() => {
        findGuiaById(token, guiaTransportistaId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findGuiaById = async (token, guiaTransportistaId) => {
        try {
            const guiaData = await GuiaService.getGuiaById(token, guiaTransportistaId);
            if (guiaData) {
                setGuiaTranspData(guiaData)
                const totalPeso = guiaData.caracteristicas
                    .reduce((acc, caracteristica) =>
                        acc + convertStringToFloatAndConvertToKgm(caracteristica.peso, caracteristica.unidadMedida), 0);

                setPesoTotal(totalPeso);
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }


    const renderTitle = () => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>Detalle de Mercadería</DialogTitle>
        )
    }

    function convertStringToFloatAndConvertToKgm(pesoStr, unidadMedida) {
        if (pesoStr === null || pesoStr === undefined || pesoStr.trim() === "") {
            return 0; // o puedes devolver otro valor predeterminado
        }
        let pesoConvert = parseFloat(pesoStr);
        if (unidadMedida != null && unidadMedida.trim() != "" && unidadMedida == "TNE") {
            pesoConvert = pesoConvert * 1000;
        }
        return pesoConvert;
    }

    const renderTable = () => {
        return (
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((column) => (
                                <TableCell
                                    key={`${column.id}-${column.label}`}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            (guiaTranspData === null) ?
                                (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data-key'}>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                </TableRow>)
                                :
                                (guiaTranspData.caracteristicas.map(caracteristica => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={`id-${caracteristica.id}`}>
                                        <TableCell align='center'>
                                            {caracteristica.cantidad || "---"}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {caracteristica.unidadMedidaCantidad || "---"}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {  caracteristica.tipoMercaderia=='Peso'? 
                                                caracteristica.nombre + " x "+ caracteristica.peso+" "+caracteristica.unidadMedida :caracteristica.nombre
                                            }
                                        </TableCell>
                                        <TableCell align='center'>
                                            {caracteristica.precioUnitario || "---"}
                                        </TableCell>
                                        <TableCell key="options" align="center">
                                            {caracteristica.total || "---"}
                                        </TableCell>
                                    </TableRow>
                                )))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                <Table>
                    {(guiaTranspData === null) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>) :
                        (guiaTranspData.caracteristicas.map(caracteristica => (
                            <TableBody key={`id-${caracteristica.id}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Cantidad:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.cantidad}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Unid. med. cant:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.unidadMedidaCantidad}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Descripción:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {  caracteristica.tipoMercaderia=='Peso'? 
                                                    caracteristica.nombre + " x "+ caracteristica.peso+" "+caracteristica.unidadMedida :caracteristica.nombre
                                                }
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Precio Unit.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {caracteristica.precioUnitario}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Total:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.total}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };


    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0rem 1.25rem 0.5rem 1.25rem' }}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(isSmUp) ? (renderTable()) : (renderCards())}
                </Paper>
                <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} >
                    <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                        <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            {`Valor venta: ${guiaTranspData !== null ? (guiaTranspData.valorVenta !== null ? guiaTranspData.valorVenta : '---') : '---'}`}
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            {`I.G.V.: ${guiaTranspData !== null ? (guiaTranspData.igv !== null ? guiaTranspData.igv : '---') : '---'}`}
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            {`Total: ${guiaTranspData !== null ? (guiaTranspData.total !== null ? guiaTranspData.total : '---') : '---'}`}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {`Guia Remitente: ${guiaTranspData !== null ? guiaTranspData.guiaRemitente : '---'}`}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {`Observaciones Internas: ${guiaTranspData !== null ? (guiaTranspData.observacionesInternas !== null ? guiaTranspData.observacionesInternas : '---') : '---'}`}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {`Peso Total: ${guiaTranspData !== null ? pesoTotal+' KGM' : '---'}`}
                    </Grid>
                </Grid>
            </DialogContent>
        )
    }

    return (
        <Fragment>
            {renderTitle(title)}
            {renderContent()}
        </Fragment>
    )
}

export default GuiaTransportistaDetail