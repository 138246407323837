const API_BASE_URL_MODULOS = `${process.env.REACT_APP_BACKEND_SEGURIDAD_URL}/modulos`;
const API_BASE_URL_MODULOSBYUSUARIO = `${process.env.REACT_APP_BACKEND_SEGURIDAD_URL}/usuarios/modulos`;
const API_BASE_URL_MODULOSANDSUCURSALESBYUSUARIO = `${process.env.REACT_APP_BACKEND_SEGURIDAD_URL}/usuarios/modsuc`;

const ModuloServices = {
    getModulos: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL_MODULOS}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            const statusCode = response.status;

            if(statusCode === 200) 
                return await response.json();
            else if(statusCode === 209)
                return null;
            else if(statusCode === 401)
                throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
            else if(statusCode === 403)
                throw new Error(`No posee los permisos necesarios para realizar la acción`);
            else
                throw new Error(`Error al obtener datos, contacte al administrador - ${statusCode}`);
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    getModulosByUsuario: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL_MODULOSBYUSUARIO}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            const statusCode = response.status;

            if(statusCode === 200) 
                return await response.json();
            else if(statusCode === 209)
                return null;
            else if(statusCode === 401)
                throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
            else if(statusCode === 403)
                throw new Error(`No posee los permisos necesarios para realizar la acción`);
            else
                throw new Error(`Error al obtener datos, contacte al administrador - ${statusCode}`);
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getModulosAndSucursalesByUsuario: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL_MODULOSANDSUCURSALESBYUSUARIO}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            const statusCode = response.status;

            if(statusCode === 200) 
                return await response.json();
            else if(statusCode === 209)
                return null;
            else if(statusCode === 401)
                throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
            else if(statusCode === 403)
                throw new Error(`No posee los permisos necesarios para realizar la acción`);
            else
                throw new Error(`Error al obtener datos, contacte al administrador - ${statusCode}`);
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    }
};

export default ModuloServices;