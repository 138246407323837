const API_BASE_URL = `${process.env.REACT_APP_BACKEND_COMERCIAL_URL}/clientes`;


const manageResponse = async (response) => {
    const statusCode = response.status;

    if(statusCode === 200) 
        return await response.json();
    if(statusCode === 201) 
        return await response.json();
    else if(statusCode === 204)
        return null;
    else if(statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if(statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if(statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if(statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const ClientesService = {

    getClientes: async (token, page, nroDocumento, razonSocial) => {
        try {
            const nroDocumentoFilter = nroDocumento !== '' ? `&nroDocumento=${nroDocumento}` : ''
            const razonSocialFilter = razonSocial !== '' ? `&razonSocial=${razonSocial}` : ''
            const allfilters = nroDocumentoFilter + razonSocialFilter
            const response = await fetch(`${API_BASE_URL}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getClienteById: async (token, clienteId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/documento/${clienteId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    saveCliente: async (token, cliente) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cliente)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    updateCliente: async (token, cliente) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cliente)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    deleteClienteById: async (token, clienteId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/eliminar/${clienteId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
}

export default ClientesService;