import { Fragment } from 'react';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


const DownloadPdfDialog = (props) => {

    const handleClose = () => {
        props.setOpen(false)
        props.setDisabledReporte(false)
    }

    const handleDelete = () => {
        props.deleteFunction();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <Fragment>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    {props.contentMessage}
                </DialogContent>
                {props.isFinished == 1 ?
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                        <Button onClick={handleDelete} variant="contained" >Aceptar</Button>
                    </DialogActions>: null
                }
                {props.isFinished == 2 ?
                    <div style={{ justifyContent: "center", display: "flex" , paddingBottom:'0.80rem'}}>
                        Generando PDF...
                        <CircularProgress />
                    </div> : null
                }
                {props.isFinished == 3 ?
                    <div style={{ justifyContent: "center", display: "flex",  paddingBottom:'0.80rem' }}>
                        {props.pdfDownload}
                    </div>
                    : null
                }
            </Fragment>
        </Dialog>
    )
}

export default DownloadPdfDialog