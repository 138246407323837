import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Componente principal
export default function PDFGenerator(props) {

  const finishDownload = () => {
    const checkDownloadStatus = setInterval(() => {
      const downloadedFile = document.getElementById('pdf-download-link');
      if (downloadedFile) {
        props.setIsFinished(1);
        clearInterval(checkDownloadStatus); //
      }
    }, 1000); // Verificar cada segundo
  };

  return (
    <PDFDownloadLink id="pdf-download-link" onClick={finishDownload}  document={props.documento} fileName={props.fileName} style={{ textDecoration: 'none', color: 'white' }}>
      {({ blob, url, loading, error }) =>
        loading ? 'Generando PDF...' : 'Descargar PDF'
      }
    </PDFDownloadLink>
  );
};