import Dialog from '@mui/material/Dialog'
import { Fragment, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { green } from '@mui/material/colors';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import UsuarioServices from '../services/user';

const ChangeTempPasswordDialog = (props) => {
    const [tempPass, setTempPass] = useState('')
    const [newPass, setNewPass] = useState('')

    const renderTempPassTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id="tempPass-textfield"
                    type="password"
                    label="Contraseña temporal"
                    variant="outlined"
                    value={tempPass}
                    onChange={(event) => setTempPass(event.target.value)}
                    size='small'
                    fullWidth
                    disabled={false}>
                </TextField>
            </Grid>
        )
    }

    const renderNewPassTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id="newPass-textfield"
                    type="password"
                    label="Nueva contraseña"
                    variant="outlined"
                    value={newPass}
                    onChange={(event) => setNewPass(event.target.value)}
                    size='small'
                    fullWidth
                    disabled={false}>
                </TextField>
            </Grid>
        )
    }

    const handleOkButton = () => {
        if(tempPass !== '' && newPass !== '') {
            updateTempPassword();
        }
        else {
            props.setSeverityAlert('warning')
            props.setAlertMessage('Llenar los campos correctamente')
            props.setOpenAlert(true)
        }
    }

    const updateTempPassword = async () => {
            try {
                const response = await UsuarioServices.updateTempPass(window.localStorage.getItem('loggedDashboard').slice(1,-1), buildRequestBody())
                if (response.status === 200) {
                    props.setSeverityAlert('success')
                    props.setAlertMessage('Contraseña actualizada exitosamente')
                    props.setOpenAlert(true)
                    props.handleCloseDialog()
                }
                else if(response.status === 409) {
                    props.setSeverityAlert('error')
                    props.setAlertMessage('La contraseña temporal es incorrecta')
                    props.setOpenAlert(true)
                    props.handleCloseDialog()
                }
                else{
                    props.setSeverityAlert('error')
                    props.setAlertMessage('Ocurrió un error al intentar actualizar la contraseña, intente nuevamente')
                    props.setOpenAlert(true)
                    props.handleCloseDialog()
                }
            }
            catch(error){
                props.setSeverityAlert('error')
                props.setAlertMessage('Ocurrió un error al intentar actualizar la contraseña, intente nuevamente')
                props.setOpenAlert(true)
                props.handleCloseDialog()
            }
    }

    const buildRequestBody = () => {
        const requestBody = {
            oldPassword: tempPass,
            newPassword: newPass
        }
        return requestBody
    }

    const renderTitle = (title) => {
        return (
            <DialogTitle
                style={{ padding: '1rem 1.25rem 0.5rem 1.25rem'}}
                sx={{
                    //my: 8,
                    //mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}
            >
                <Avatar sx={{ m: 1, bgcolor:  green[800] }}>
                    <LockOutlinedIcon />
                </Avatar>
                {title}
            </DialogTitle>
        )
    }

    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0.5rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {renderTempPassTextField()}
                    {renderNewPassTextField()}
                </Grid>
            </DialogContent>
        )
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={props.handleCancelChangePassDialog} variant="outlined" color="error">Cancelar</Button>
                <Button
                    onClick={handleOkButton}
                    variant="contained"
                    disabled={false}
                    sx={{ m: 1, bgcolor:  green[800] }}
                >
                    Guardar
                </Button>
            </DialogActions>
        )
    }

    

    return (
        <Dialog fullWidth maxWidth={'xs'} open={props.open} onClose={() => props.noAction()}>
            <Fragment>
                {renderTitle('Cambiar contraseña temporal')}
                {renderContent()}
                {renderActionButtons()}
            </Fragment>
        </Dialog>
    )
}

export default ChangeTempPasswordDialog