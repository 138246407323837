import React, { useState, useEffect, Fragment } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogContentText, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Alert from '../../components/Alert'

import RolServices from "../../services/roles";
import ModuloServices from "../../services/modulos";

export default function RolesViewDetails(props) {

    const [openAlertError, setOpenAlertError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [modulosResponse, setModulosResponse] = useState(null);
    const [rolResponse, setRolResponse] = useState(null);
    const [nombreRol, setNombreRol] = useState("");
    const [descripcionRol, setDescripcionRol] = useState("");

    useEffect(() => {
        findAllModulos(props.token);
        if (props.isCreate === false) {
            findRolById(props.token, props.rolId)
        }
    }, []);

    useEffect(() => {
        if (modulosResponse !== null && rolResponse !== null) {
            setNombreRol(rolResponse.nombre)
            setDescripcionRol(rolResponse.descripcion)
            compareModulosAndRol()
        }
    }, [rolResponse]);

    const findAllModulos = async (token) => {
        try {
            const responseData = await ModuloServices.getModulos(token);
            setModulosResponse(addAccionesToModulos(responseData))
            setErrorMessage(null)
        }
        catch (error) {
            setErrorMessage(error.message);
        }
    };

    const findRolById = async (token, rolId) => {
        try {
            const responseData = await RolServices.getRolById(token, rolId);
            setRolResponse(responseData);
            setErrorMessage(null)
        }
        catch (error) {
            setErrorMessage(error.message);
            setOpenAlertError(true)
        }
    };

    const compareModulosAndRol = async () => {
        for (const supermodulo in rolResponse.modulos) {
            if (modulosResponse.hasOwnProperty(supermodulo)) {
                const modulosFromModulosResponse = modulosResponse[supermodulo]
                const modulosFromRolResponse = rolResponse.modulos[supermodulo]

                for (const modulo of modulosFromRolResponse) {
                    const indexModuloEncontrado = modulosFromModulosResponse.findIndex(mod => mod.id === modulo.id)
                    if (indexModuloEncontrado !== -1) {
                        setModulosResponse(prevState => {
                            const newState = { ...prevState }
                            newState[supermodulo][indexModuloEncontrado].activo = true
                            const newAcciones = [false, false, false, false]
                            for (const accion of modulo.acciones) {
                                newAcciones[accion.id - 1] = true
                            }
                            newState[supermodulo][indexModuloEncontrado].acciones = newAcciones
                            return newState
                        })
                    }
                }
            }
        }
    }

    const addAccionesToModulos = (modulosAgrupados) => {
        const response = {}
        Object.keys(modulosAgrupados).forEach((supermodulo) => {
            const newModulos = modulosAgrupados[supermodulo].map((modulo) => ({
                ...modulo,
                activo: false,
                acciones: [false, false, false, false]
            }))
            response[supermodulo] = newModulos
        });
        return response;
    };

    const handleClose = () => {
        props.setOpenCreate(false);
    };

    const convertResponseToRequestSave = () => {
        const requestRol = {
            nombre: nombreRol,
            descripcion: descripcionRol,
            modulos: Object.values(modulosResponse)
                .flatMap(modulos => modulos)
                .filter(modulo => modulo.activo)
                .map(({ id, acciones }) => ({
                    id,
                    acciones: acciones.reduce((indicesSeleccionados, valor, index) => {
                        if (valor) {
                            indicesSeleccionados.push(index + 1);
                        }
                        return indicesSeleccionados;
                    }, [])
                }))
        }
        return requestRol
    }

    const convertResponseToRequestUpdate = () => {
        const requestRol = {
            id: props.rolId,
            nombre: nombreRol,
            descripcion: descripcionRol,
            activo: true,
            modulos: Object.values(modulosResponse)
                .flatMap(modulos => modulos)
                .filter(modulo => modulo.activo)
                .map(({ id, acciones }) => ({
                    id,
                    acciones: acciones.reduce((indicesSeleccionados, valor, index) => {
                        if (valor) {
                            indicesSeleccionados.push(index + 1);
                        }
                        return indicesSeleccionados;
                    }, [])
                }))
        }
        return requestRol
    }

    const handleGuardar = async () => {
        const tokenActual = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        try {
            if (nombreRol.length <= 0 || descripcionRol <= 0) {
                props.setMsjAlertExitoso("Llenar los campos correctamente")
                props.setSeverityAlert('warning')
                props.setOpenAlertOk(true);
            }
            else {
                if (props.isCreate) {
                    await RolServices.saveRol(props.token, convertResponseToRequestSave());
                    props.setMsjAlertExitoso("Rol guardado exitosamente")
                }
                else {
                    await RolServices.updateRol(props.token, convertResponseToRequestUpdate());
                    props.setMsjAlertExitoso("Rol actualizado exitosamente")
                }

                setErrorMessage(null)
                setOpenAlertError(false)

                props.setSeverityAlert('success')
                props.setOpenAlertOk(true);
                props.reloadAllEntidad(tokenActual, 0, "", "", "")
                props.setOpenCreate(false);
            }
        }
        catch (error) {
            props.setMsjAlertExitoso(error.message)
            props.setSeverityAlert('error')
            props.setOpenAlertOk(true);
        }
    }

    const acciones = ["Registrar", "Editar", "Eliminar"];

    const handleAccionesChange = (event, index, moduloToUpdate) => {
        setModulosResponse(modulos => {
            const newModulos = { ...modulos }
            newModulos[moduloToUpdate.supermodulo] = newModulos[moduloToUpdate.supermodulo].map(modulo => {
                if (modulo.id === moduloToUpdate.id) {
                    const nuevasAcciones = [...modulo.acciones]
                    nuevasAcciones[index + 1] = event.target.checked
                    return {
                        ...modulo,
                        acciones: nuevasAcciones
                    }
                }
                return modulo
            })
            return newModulos
        })
    };

    const generateAcciones = (modulo) => {
        return acciones.map((accion, index) => (
            <FormControlLabel
                key={`${modulo} - ${accion}`}
                label={accion}
                disabled={!modulo.activo}
                control={<Checkbox checked={modulo.acciones[index + 1]} onChange={(event) => handleAccionesChange(event, index, modulo)} />}
            />
        ))
    }

    const handleModuloChange = (event, moduloToUpdate) => {
        //setChecked([event.target.checked, event.target.checked, event.target.checked]);
        setModulosResponse(modulos => {
            const newModulos = { ...modulos }
            newModulos[moduloToUpdate.supermodulo] = newModulos[moduloToUpdate.supermodulo].map(modulo => {
                if (modulo.id === moduloToUpdate.id) {
                    return {
                        ...modulo,
                        activo: event.target.checked,
                        acciones: [event.target.checked, false, false, false]
                    }
                }
                return modulo
            })
            return newModulos
        })
    };

    return (
        <Fragment>
            <DialogTitle style={{ paddingTop: '1rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem' }} align="center">{props.title}</DialogTitle>

            <div style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem' }} >
                <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} alignContent={"center"}>
                    <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                        <TextField
                            autoComplete="off"
                            value={nombreRol !== null ? nombreRol : ""} label="Nombre" variant="outlined" size="small" fullWidth
                            onChange={(event) => { setNombreRol(event.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                        <TextField
                            autoComplete="off"
                            value={descripcionRol !== null ? descripcionRol : ""} label="Descripción" variant="outlined" size="small" fullWidth
                            onChange={(event) => { setDescripcionRol(event.target.value); }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                Módulos
            </div>

            <DialogContent style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginLeft: '1rem' }}>
                {modulosResponse !== null ?
                    Object.keys(modulosResponse).map((superModulo) => (
                        <div key={superModulo} >
                            {superModulo}<br />
                            {modulosResponse[superModulo].map((modulo) => (
                                <div key={`${superModulo} - ${modulo.nombre}`} style={{ marginLeft: '1.5rem' }}>
                                    <FormControlLabel
                                        label={modulo.nombre}
                                        key={modulo.nombre}
                                        control={
                                            <Checkbox
                                                checked={modulo.activo}
                                                onChange={(event) => handleModuloChange(event, modulo)}
                                            />
                                        }
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '2rem' }}>
                                        {generateAcciones(modulo)}
                                    </Box>
                                </div>
                            ))}
                        </div>
                    ))
                    :
                    <div>No se ha encontrado ningún módulo</div>
                }
            </DialogContent>

            <DialogActions style={{ paddingTop: '0.5rem', paddingBottom: '1rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', justifyContent: 'flex-end' }}>
                <Button onClick={() => handleClose()} variant="outlined" color="error" style={{ marginRight: '0.5rem' }}>Cancelar</Button>
                <Button onClick={() => handleGuardar()} variant="contained" style={{ marginLeft: '0.5rem' }} >Guardar</Button>
            </DialogActions>

            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje={errorMessage} severity="error" />
        </Fragment>
    );
}