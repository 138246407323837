const API_BASE_URL = `${process.env.REACT_APP_BACKEND_ALMACEN_URL}/guias-transportista`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if (statusCode === 200)
        return await response.json();
    if (statusCode === 201)
        return await response.json();
    else if (statusCode === 204)
        return null;
    else if (statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if (statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if (statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if (statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const GuiaService = {

    getGuias: async (token, page, nroGuia, fecha, nroDocRemitente, razonRemitenteFilter, nroDocDestinatario, razonDestinatarioFilter) => {
        try {
            const remitenteFilter = nroDocRemitente !== '' ? `&remitenteRuc=${nroDocRemitente}` : ''
            const destinatarioFilter = nroDocDestinatario !== '' ? `&destinatarioRuc=${nroDocDestinatario}` : ''
            const fechaFilter = fecha !== '' ? `&fechaRegistro=${fecha}` : ''
            const nroGuiaFilter = nroGuia !== '' ? `&numeroGuia=${nroGuia}` : ''
            const razonRemFilter = razonRemitenteFilter !== '' ? `&remitenteRazon=${razonRemitenteFilter}` : ''
            const razonDestFilter = razonDestinatarioFilter !== '' ? `&destinatarioRazon=${razonDestinatarioFilter}` : ''

            const allfilters = remitenteFilter + destinatarioFilter + fechaFilter + nroGuiaFilter + razonRemFilter + razonDestFilter

            const response = await fetch(`${API_BASE_URL}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getDetalle: async (token, idManifiesto, idGuia) => {
        try {
            var filter = ''
            if(idManifiesto){
                filter = `?idManifiesto=${idManifiesto}`
            }else{
                filter = `?idGuia=${idGuia}` 
            }
            const response = await fetch(`${API_BASE_URL}/descarga${filter}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)

            // return [
            //     {
            //         "serie":"V001",
            //         "codigo":"1",
            //         "fechaRegistro":"13/06/2024",
            //         "pesoTotal":"500",
            //         "remitente":{
            //             "ruc":"20609102935",
            //             "razonSocial":"GRUPO WAGTAIL S.A.C.",
            //             "direccion":"AV. ARGENTINA NRO. 232 LIMA,LIMA,LIMA"
            //         },
            //         "destinatario":{
            //             "ruc":"20482543813",
            //             "razonSocial":"TRANSPORTES MELLIZO EXPRES S.A.C.",
            //             "direccion":"JR.SANTA CRUZ NRO.230 URB. MIRAFLORES CHIMBOTE,SANTA,ANCASH"
            //         },
            //         "transporte":{
            //             "nroPlaca":"C5J854",
            //             "nombreChofer":"JESUS AMADO SOLANO CRUZADO",
            //             "nroDocChofer":"48662732",
            //             "licenciaChofer":"D48662732",
            //         },
            //         "caracteristicas":[
            //             {
            //                 "idCaracteristicas":"536995",
            //                 "cantidad":"2",
            //                 "nombre":"CAJAS C/ BATERIA Y REPUESTOS VARIOS"
            //             }
            //         ]
            //     },
            //     {   "serie":"V001",
            //         "codigo":"2",
            //         "fechaRegistro":"13/06/2024",
            //         "pesoTotal":"400",
            //         "remitente":{
            //             "ruc":"10609102935",
            //             "razonSocial":"1 GRUPO WAGTAIL S.A.C.",
            //             "direccion":"1 AV. ARGENTINA NRO. 232 LIMA,LIMA,LIMA"
            //         },
            //         "destinatario":{
            //             "ruc":"10482543813",
            //             "razonSocial":"1 TRANSPORTES MELLIZO EXPRES S.A.C.",
            //             "direccion":"1 JR.SANTA CRUZ NRO.230 URB. MIRAFLORES CHIMBOTE,SANTA,ANCASH"
            //         },
            //         "transporte":{
            //             "nroPlaca":"C5J854",
            //             "nombreChofer":"JESUS AMADO SOLANO CRUZADO",
            //             "nroDocchofer":"48662732",
            //             "licenciaChofer":"D48662732",
            //         },
            //         "caracteristicas":[
            //             {
            //                 "idCaracteristicas":"536997",
            //                 "cantidad":"3",
            //                 "nombre":"CAJAS C/ BATERIA Y REPUESTOS VARIOS"
            //             },
            //             {
            //                 "idCaracteristicas":"536998",
            //                 "cantidad":"4",
            //                 "nombre":"CAJAS C/ BATERIA Y REPUESTOS"
            //             }
            //         ]
            //     }
            // ]

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    
    getDestinosCuentas: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL}/cuentas/destino`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    
    getGuiaById: async (token, guiaTranspId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/details/${guiaTranspId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

}

export default GuiaService;