import { Fragment } from 'react';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DeleteObjectDialog = (props) => {

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleDelete = () => {
        props.deleteFunction();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <Fragment>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    {props.contentMessage}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                    <Button onClick={handleDelete} variant="contained" >Aceptar</Button>
                </DialogActions>
            </Fragment>
        </Dialog>
    )
}

export default DeleteObjectDialog