import { useState, useEffect, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import AutoComplete from '../../components/AutoComplete';

import EmpleadosService from '../../services/empleados';
import TiposDocumentoService from '../../services/tiposdocumento';
import AreasService from '../../services/areas';

const EmpleadosCreateUpdate = (props) => {
    const [empleadoToUpdate, setEmpleadoToUpdate] = useState(null)

    const [tiposDocumento, setTiposDocumento] = useState([])
    const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState(null)
    const [inputTipoDocumento, setInputTipoDocumento] = useState('')

    const [docIdent, setDocIdent] = useState('')
    const [nombres, setNombres] = useState('')
    const [apellidos, setApellidos] = useState('')
    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [celular, setCelular] = useState('')
    const [fechaIngreso, setFechaIngreso] = useState('')
    const [isContinue, setIsContinue] = useState(false)
    const [fechaSalida, setFechaSalida] = useState('')
    const [direccion, setDireccion] = useState('')

    const [areas, setAreas] = useState([])
    const [areaSelected, setAreaSelected] = useState(null);
    const [inputArea, setInputArea] = useState('');
    const areaTransportes = {
        id: 1
    }

    const [licencia, setLicencia] = useState('')

    const [btnSaveDisabled, setBtnSaveDisabled] = useState(true)

    useEffect(() => {
        loadAllData(props.token, props.isCreateDialog, props.empleadoId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadAllData = async (token, isCreateDialog, empleadoId) => {
        try {
            let responseData = null
            if (!isCreateDialog) {
                responseData = await EmpleadosService.getEmpleadoById(token, empleadoId)
                setEmpleadoToUpdate(responseData)
            }
            loadDataToForms(token, isCreateDialog, responseData)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const loadDataToForms = (token, isCreateDialog, empleadoToUpdate) => {
        findAllTiposDocumento(token, empleadoToUpdate)
        findAllAreas(token, empleadoToUpdate)
        if (!isCreateDialog && empleadoToUpdate) {
            setDocIdent(empleadoToUpdate.nroDocIdentidad || '')
            setNombres(empleadoToUpdate.nombres || '')
            setApellidos(empleadoToUpdate.apellidos || '')
            setFechaNacimiento(empleadoToUpdate.fechaNacimiento || '')
            setCelular(empleadoToUpdate.celular || '')
            setFechaIngreso(empleadoToUpdate.fechaIngreso || '')
            setIsContinue(empleadoToUpdate.isContinue || false)
            setFechaSalida(empleadoToUpdate.fechaSalida || '')
            setDireccion(empleadoToUpdate.direccion || '')
            setLicencia(empleadoToUpdate.licencia || '')
            setBtnSaveDisabled(false)
        }
        else if (isCreateDialog) {
            setBtnSaveDisabled(false)
        }
    }

    const findAllTiposDocumento = async (token, empleadoToUpdate) => {
        try {
            const responseData = await TiposDocumentoService.getTiposDocumento(token);
            setTiposDocumento(responseData);
            setTipoDocumentoSelected(responseData && empleadoToUpdate ? empleadoToUpdate.tipoDocumento : null)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const findAllAreas = async (token, empleadoToUpdate) => {
        try {
            const responseData = await AreasService.getAreas(token);
            setAreas(responseData);
            setAreaSelected(responseData && empleadoToUpdate ? empleadoToUpdate.area : null)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const renderTitle = (title) => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>{title}</DialogTitle>
        )
    }

    const autoCompleteObjectComparator = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/;

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    function handleOptionLabelNombre(objeto) {
        return objeto.nombre
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if (/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfLetterOrSpace = (newValue, stateFunction) => {
        const letters = /^[a-zA-Z\s]*$/
        if (letters.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfDate = (newValue, stateFunction) => {
        const formatoFecha = /^\d{0,4}(-\d{0,2}){0,2}$/
        if (formatoFecha.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }

    const handleTipoDocumentoSelectedChange = (event, newValue) => {
        setTipoDocumentoSelected(newValue ? newValue : null)
        setDocIdent('')
    }

    const handleInputTipoDocumentoChange = (event, newValue) => {
        setInputTipoDocumento(newValue)
    }

    const renderTipoDocumento = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'tipodocumento-box',
                    tipoDocumentoSelected,
                    handleTipoDocumentoSelectedChange,
                    inputTipoDocumento,
                    handleInputTipoDocumentoChange,
                    tiposDocumento,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Tipo Doc. Ident.',
                    'small'
                )}
            </Grid>
        )
    }

    const renderDocIdentTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="docidentidad-textfield"
                    label="Doc. Ident."
                    variant="outlined"
                    value={docIdent}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setDocIdent)}
                    size='small'
                    fullWidth
                    disabled={tipoDocumentoSelected === null}>
                </TextField>
            </Grid>
        )
    }

    const renderNombresTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="nombres-textfield"
                    label="Nombres"
                    variant="outlined"
                    value={nombres}
                    onChange={(event) => changeStateOnlyIfLetterOrSpace(event.target.value, setNombres)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderApellidosTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="apellidos-textfield"
                    label="Apellidos"
                    variant="outlined"
                    value={apellidos}
                    onChange={(event) => changeStateOnlyIfLetterOrSpace(event.target.value, setApellidos)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderFechaNacimientoTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="fechanacimiento-textfield"
                    label="Fecha Nac. (aaaa-mm-dd)"
                    variant="outlined"
                    value={fechaNacimiento}
                    onChange={(event) => changeStateOnlyIfDate(event.target.value, setFechaNacimiento)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderCelularTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="celular-textfield"
                    label="Celular"
                    variant="outlined"
                    value={celular}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setCelular)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleAreaSelectedChange = (event, newValue) => {
        setAreaSelected(newValue ? newValue : null)
    }

    const handleInputAreaChange = (event, newValue) => {
        setInputArea(newValue)
    }

    const renderArea = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'area-box',
                    areaSelected,
                    handleAreaSelectedChange,
                    inputArea,
                    handleInputAreaChange,
                    areas,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Area',
                    'small'
                )}
            </Grid>
        )
    }

    const renderFechaIngresoTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="fechaingreso-textfield"
                    label="Fecha Ingreso (aaaa-mm-dd)"
                    variant="outlined"
                    value={fechaIngreso}
                    onChange={(event) => changeStateOnlyIfDate(event.target.value, setFechaIngreso)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleContinueCheckbox = (event) => {
        setIsContinue(event.target.checked);
        setFechaSalida('')
    };

    const renderContinueCheckbox = () => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} display={'flex'} alignItems='center' justifyContent={props.isSmUp ? 'left' : 'center'} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <FormControlLabel
                    label='Laborando actualmente'
                    control={
                        <Checkbox
                            id={'iscontinue-checkbox'}
                            checked={isContinue}
                            onChange={handleContinueCheckbox}
                            size='small'
                        //inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                />
            </Grid>
        )
    }

    const renderFechaSalidaTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="fechasalida-textfield"
                    label="Fecha Salida (aaaa-mm-dd)"
                    variant="outlined"
                    value={fechaSalida}
                    onChange={(event) => changeStateOnlyIfDate(event.target.value, setFechaSalida)}
                    size='small'
                    fullWidth
                    disabled={isContinue}>
                </TextField>
            </Grid>
        )
    }

    const renderDireccionTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="direccion-textfield"
                    label="Direccion"
                    variant="outlined"
                    value={direccion}
                    onChange={(event) => setDireccion(event.target.value)}
                    size='small'
                    fullWidth
                >
                </TextField>
            </Grid>
        )
    }

    const renderLicenciaTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="licencia-textfield"
                    label="Licencia"
                    variant="outlined"
                    value={licencia}
                    onChange={(event) => setLicencia(event.target.value)}
                    size='small'
                    fullWidth
                >
                </TextField>
            </Grid>
        )
    }

    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0.5rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {renderTipoDocumento()}
                    {renderDocIdentTextField()}
                    {renderNombresTextField()}
                    {renderApellidosTextField()}
                    {renderFechaNacimientoTextField()}
                    {renderCelularTextField()}
                    {renderDireccionTextField()}
                    {renderArea()}
                    {renderFechaIngresoTextField()}
                    {areaSelected && areaSelected.id === areaTransportes.id ? renderLicenciaTextField() : null}
                    {props.isCreateDialog ? null : renderContinueCheckbox()}
                    {props.isCreateDialog ? null : renderFechaSalidaTextField()}
                </Grid>
            </DialogContent>
        )
    }

    const handleCloseButton = () => {
        props.setDialogOpen(false);
    };

    const buildRequestBody = (isCreate) => {
        const requestBody = {
            tipoDocumentoId: tipoDocumentoSelected.id,
            nroDocIdentidad: docIdent,
            nombres: nombres,
            apellidos: apellidos,
            celular: celular,
            areaId: areaSelected.id,
            fechaNacimiento: fechaNacimiento,
            fechaIngreso: fechaIngreso,
            direccion: direccion,
            licencia: licencia
        }

        if (!isCreate) {
            requestBody.id = empleadoToUpdate.id;
            requestBody.isContinue = isContinue;
            requestBody.fechaSalida = !isContinue ? fechaSalida : null
        }

        return requestBody
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        props.setSeverityAlert(severityAlert)
        props.setAlertMessage(alertMessage)
        props.setOpenAlert(openAlert)
    }

    const saveEmpleado = async (token, isCreate, reloadData, flagForReloadData, setDialogOpen) => {
        try {
            const response = ((isCreate) ? (await EmpleadosService.saveEmpleado(token, buildRequestBody(isCreate))) : (await EmpleadosService.updateEmpleado(token, buildRequestBody(isCreate))))
            if (response) {
                manageAlert('success', ('Empleado ' + (isCreate ? 'creado' : 'actualizado') + ' exitosamente'), true)
                reloadData(!flagForReloadData)
                setDialogOpen(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const handleSaveButton = () => {
        if (
            tipoDocumentoSelected !== null
            && docIdent !== ''
            && nombres !== ''
            && apellidos !== ''
            && fechaNacimiento !== ''
            && celular !== ''
            && direccion !== ''
            && areaSelected !== null
            && fechaIngreso !== ''
            && (props.isCreateDialog || (isContinue || fechaSalida !== ''))
        ) {
            saveEmpleado(props.token, props.isCreateDialog, props.reloadData, props.flagForReloadData, props.setDialogOpen)
            //manageAlert('success', 'Campos llenados correctamente', true)
        }
        else {
            manageAlert('warning', 'Llenar los campos correctamente', true)
        }
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={handleCloseButton} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={handleSaveButton} variant="contained" disabled={btnSaveDisabled} >Guardar</Button>
            </DialogActions>
        )
    }

    return (
        <Fragment>
            {renderTitle(props.title)}
            {renderContent()}
            {renderActionButtons()}
        </Fragment>
    )
}

export default EmpleadosCreateUpdate