import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function AutoComplete({
    id,
    value,
    onChange,
    inputValue,
    onInputChange,
    options,
    getOptionLabel,
    isOptionEqualToValue,
    fullWidth,
    width,
    label,
    size,
    disabled
}) {
    return (
        <Autocomplete
            id={id}
            value={value}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            fullWidth={fullWidth}
            //sx={{ width: width }}
            renderInput={(params) => <TextField {...params} label={label} fullWidth />}
            size={size}
            disabled = {disabled}
        />
    );
}