import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Image from '../img/home.png'; // Import using relative path
import Login  from "../services/login";
import Alert  from './Alert'
import { green } from '@mui/material/colors';

import CustomizeSnackBar from './Snackbar';

import ChangeTempPasswordDialog from '../modules/ChangeTempPassword';

export default function ThemeLogin(props) {

    
    const theme = createTheme();
    const [openAlert, setOpenAlert] = React.useState(false);


    const [openChangePassTemp, setOpenChangePassTemp] = React.useState(false)

    const handleCloseChangePassDialog = () => {
        window.localStorage.removeItem('loggedDashboard')
        setOpenChangePassTemp(false)
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    const handleCancelChangePassDialog = () => {
        window.localStorage.removeItem('loggedDashboard')
        setOpenChangePassTemp(false)
        window.location.reload();
    }
    
    let loginResponse = null;
    let loginError = null;

    async function handleSubmit (event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const credentials = {
            username: data.get('usuario'),
            password: data.get('password')
        }
        try{
            loginResponse =  await Login.login(credentials);
        }catch (error) {
            loginError = (error.message)
        }

        if (loginError == null) {
            window.localStorage.setItem(
                'loggedDashboard' , JSON.stringify(loginResponse.token)
            )

            if(loginResponse.isPasswordTemporal){
                setOpenChangePassTemp(true)
            }
            else{
                props.setIsLogin(true)
                
            }
        }else{
            setOpenAlert(true);
        }

    };

    const [openAlertTempPass, setOpenAlertTempPass] = React.useState(false)
    const [severityAlert, setSeverityAlert] = React.useState('info')
    const [alertMessage, setAlertMessage] = React.useState('')

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    
    return (
            <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${Image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor:  green[800] }}>
                    <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Iniciar Sesión
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="usuario"
                        label="Usuario"
                        name="usuario"
                        autoComplete="usuario"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        color='success'
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Ingresar
                    </Button>
                    </Box>
                </Box>
                </Grid>
                <Alert openAlert={openAlert} setOpenAlert={setOpenAlert} mensaje="Credenciales incorrectas" severity="error"/>
                
                <ChangeTempPasswordDialog
                    open={openChangePassTemp}
                    handleCloseDialog={handleCloseChangePassDialog}
                    handleCancelChangePassDialog={handleCancelChangePassDialog}
                    setSeverityAlert={setSeverityAlert}
                    setAlertMessage={setAlertMessage}
                    setOpenAlert={setOpenAlertTempPass}
                />
                {renderAlert(setOpenAlertTempPass, openAlertTempPass, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
        </ThemeProvider>
    );
}