const API_BASE_URL = `${process.env.REACT_APP_BACKEND_SEGURIDAD_URL}/roles`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if(statusCode === 200) 
        return await response.json();
    else if(statusCode === 204)
        return null;
    else if(statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if(statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if(statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if(statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const RolServices = {
    getRoles: async (token, page, nombreFiltro) => {
        try {
            const response = await fetch(`${API_BASE_URL}?page=${page}&nombre=${nombreFiltro!==null ? nombreFiltro : ""}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getRolById: async (token, id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/id/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    deleteRolById: async (token, id) => {
        try {
            await fetch(`${API_BASE_URL}/eliminar/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    saveRol: async (token, rol) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(rol)
            });
            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
  
    updateRol: async (token, rol) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(rol)
            });
            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getRolesCombo: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL}/combo`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

};

export default RolServices;