import React, { useState, useEffect, Fragment } from "react";
//Imports material-ui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SucursalesService from "../../services/sucursales";

//Servicios
import UsuarioServices from "../../services/user";

//componente
import Alert from '../../components/Alert'
export default function UsuarioAddUpdate(props) {

    const [rol, setRol] = useState(props.usuarioData?.rol?.id);
    const [username, setUsername] = useState(props.usuarioData?.usuario);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [msjError, setMsjError] = useState('');

    const [sucursales, setSucursales] = useState([])
    const [sucursalesSeleccionadas, setSucursalesSeleccionadas] = useState(props.usuarioData ? props.usuarioData.sucursales : [])

    const handleClose = () => {
        props.setOpenCreate(false);
    };

    const handleChangeRol = (event) => {
        setRol(event.target.value);
    };

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        getSucursales(loggedDashboardJSON)
    }, [])

    const getSucursales = async (token) => {
        try {
            const responseData = await SucursalesService.getSucursales(token)
            if(responseData) {
                setSucursales(responseData)
            }
        }
        catch (error) {
            setMsjError('Error al obtener las sucursales')
            setOpenAlertError(true);
        }
    };

    let usuarioResponse = null;
    let usuarioError = null;

    async function submitUsuario() {
        const tokenActual = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        var dataFormUsuario = {
            usuario: username,
            rolId: rol,
            sucursalesId: sucursalesSeleccionadas.map(sucursal => sucursal.id)
        }

        if (props.isCreate) {
            try {
                usuarioResponse = await UsuarioServices.createUsuario(tokenActual, dataFormUsuario);
            } catch (error) {
                usuarioError = (error.message)
            }

        } else {
            dataFormUsuario.id = props.usuarioData.id;
            try {
                usuarioResponse = await UsuarioServices.updateUsuario(tokenActual, dataFormUsuario);
            } catch (error) {
                usuarioError = (error.message)
            }
        }
        if (usuarioError == null) {
            props.reloadAllEntidad(tokenActual, 0, "", "", "")
            if (props.isCreate) {
                props.setUsuarioFinalizar(usuarioResponse.usuario);
                props.setPwdFinalizar(usuarioResponse.passwordTemporal);
                props.setOpenFinalizar(true);
            } else {
                props.setMsjAlertExitoso('Actualizado correctamente')
                props.setSeverityAlert('success')
                props.setOpenAlertOk(true);
                setOpenAlertError(false)
            }

            handleClose()
        } else {
            setMsjError(`Error al ${props.isCreate ? 'registrar' : 'actualizar'}`)
            setOpenAlertError(true);
        }

    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id

    const renderSelectSucursales = () => {
        return (
            <Autocomplete
                multiple
                id="checkboxes-sucursales"
                options={sucursales}
                value={sucursalesSeleccionadas}
                isOptionEqualToValue={autoCompleteObjectComparatorById}
                disableCloseOnSelect
                getOptionLabel={(option) => option.nombre}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                            {option.nombre}
                        </li>
                    );
                }}
                onChange={(event, newValue) => {
                    setSucursalesSeleccionadas(newValue);
                }}
                style={{}}
                renderInput={(params) => (
                    <TextField {...params} label="Sucursales" placeholder="Seleccione las sucursales correspondientes" />
                )}
            />
        )
    }

    return (
        <Fragment>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent style={{ paddingTop: '1vh' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="username"
                            label="Nombre de usuario"
                            type="text"
                            variant="standard"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>

                        <FormControl fullWidth>
                            <InputLabel>Rol</InputLabel>
                            <Select
                                labelId="rol-select-label"
                                id="rol-select"
                                value={rol}
                                label="Rol"
                                onChange={handleChangeRol}
                                fullWidth
                            >
                                {props.menuItemRol}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {renderSelectSucursales()}
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={submitUsuario} variant="contained" >Guardar</Button>
            </DialogActions>
            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje={msjError} severity="error" />
        </Fragment>
    );
}