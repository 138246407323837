import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CustomizeSnackBar = (props) => {

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        props.setOpen(false);
    };

    return (
        <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={props.severity}
                variant={props.variant}
                sx={{ width: "100%" }}
            >
                {props.message}
            </Alert>
      </Snackbar>
    )
}

export default CustomizeSnackBar