import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CustomizeSnackBar from '../../components/Snackbar';
import GuiaService from '../../services/guia';
import DateSelector from '../../components/DateSelector';
import DownloadPdfDialog from '../../components/DownloadPdfDialog';
import Button from '@mui/material/Button';
import PDFGenerator from '../../components/PDFGenerator'
import DocumentGuia from '../../components/DocumentGuia'
import LinearProgress from '@mui/material/LinearProgress';
import ListIcon from '@mui/icons-material/List';
import Dialog from '@mui/material/Dialog'
import GuiaTransportistaDetail from './GuiaTransportistaDetail';

const GuiaTransportista = (props) => {
    const [guiaData, setGuiaData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [nroGuiaFilter, setNroGuiaFilter] = useState('')
    const [fechaFilter, setFechaFilter] = useState('')
    const [nroDocRemitenteFilter, setNroDocRemitenteFilter] = useState('')
    const [razonRemitenteFilter, setRazonRemitenteFilter] = useState('')
    const [nroDocDestinatarioFilter, setNroDocDestinatarioFilter] = useState('')
    const [razonDestinatarioFilter, setRazonDestinatarioFilter] = useState('')

    const [activateFilters, setActivateFilters] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [isFinished, setIsFinished] = useState(1);
    const [selectedGuiaId, setSelectedGuiaId] = useState(null)
    const [openGuiasDialog, setOpenGuiasDialog] = useState(false)
    const [guiasDownload, setGuiasDownload] = useState();
    const [tokenActual, setTokenActual] = useState('');

    const[isDataWaitig, setIsDataWaiting] = useState(true);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllGuias(
            loggedDashboardJSON,
            currentPage - 1,
            nroGuiaFilter,
            formatDateForString(fechaFilter),
            nroDocRemitenteFilter,
            razonRemitenteFilter,
            nroDocDestinatarioFilter,
            razonDestinatarioFilter
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
    }, [currentPage, activateFilters]);

    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllGuias = async (
        token, 
        page, 
        nroGuia, 
        fecha, 
        nroDocRemitente, 
        razonRemitenteFilter, 
        nroDocDestinatario, 
        razonDestinatarioFilter
    ) => {
        setIsDataWaiting(true);
        try {
            const responseData = await GuiaService.getGuias(
                token, 
                page, 
                nroGuia, 
                fecha, 
                nroDocRemitente, 
                razonRemitenteFilter, 
                nroDocDestinatario, 
                razonDestinatarioFilter
            );
            setGuiaData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const handleFechaFilter = (date) => {
        setFechaFilter(date)
    }


    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    const renderNroGuiaFilter = () => {
        return (
            <Grid item xs={6} sm={4} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    id="nro-guia-filter"
                    label="Nro. Guia Transp."
                    variant="outlined"
                    value={nroGuiaFilter}
                    onChange={(event) => setNroGuiaFilter(event.target.value)}
                    size='small'
                    autoComplete='off'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderFechaFilter = () => {
        return (
            <Grid item xs={6} sm={4} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-filter"
                    label="Fecha"
                    selectedValue={fechaFilter}
                    onChange={handleFechaFilter}>
                </DateSelector>
            </Grid>
        )
    }

    const renderRemitenteFilter = () => {
        return (
            <Grid item xs={6} sm={4} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="nro-guia-filter"
                    label="Doc. Remitente"
                    variant="outlined"
                    value={nroDocRemitenteFilter}
                    onChange={(event) => setNroDocRemitenteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderRazonRemitenteFilter = () => {
        return (
            <Grid item xs={6} sm={4} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="razonRem-filter"
                    label="Razon Soc. Remitente"
                    variant="outlined"
                    value={razonRemitenteFilter}
                    onChange={(event) => setRazonRemitenteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }


    const renderDestinatarioFilter = () => {
        return (
            <Grid item xs={6} sm={4} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="destinatarioRuc-filter"
                    label="Doc. Destinatario"
                    variant="outlined"
                    value={nroDocDestinatarioFilter}
                    onChange={(event) => setNroDocDestinatarioFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderRazonDestinatarioFilter = () => {
        return (
            <Grid item xs={6} sm={4} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="razonDest-filter"
                    label="Razon Soc. Destinatario"
                    variant="outlined"
                    value={razonDestinatarioFilter}
                    onChange={(event) => setRazonDestinatarioFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const handleClearFilters = () => {
        setNroGuiaFilter('')
        setFechaFilter('')
        setNroDocRemitenteFilter('')
        setRazonRemitenteFilter('')
        setNroDocDestinatarioFilter('')
        setRazonDestinatarioFilter('')
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={12} sm={12} md={1} lg={1} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderNroGuiaFilter()}
                        {renderFechaFilter()}
                        {renderRemitenteFilter()}
                        {renderRazonRemitenteFilter()}
                        {renderDestinatarioFilter()}
                        {renderRazonDestinatarioFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnNroGuiaTransp', align: 'center', label: 'Nro. Guia Transp.', minWidth: 75, format: 'string' },
        { id: 'columnFecha', align: 'center', label: 'Fecha', minWidth: 75, format: 'string' },
        { id: 'columnRemitente', align: 'center', label: 'Remitente', minWidth: 75, format: 'string' },
        { id: 'columnDestinatario', align: 'center', label: 'Destintario', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Estado', minWidth: 75, format: 'string' },
        { id: 'columnPrecio', align: 'center', label: 'Monto Total', minWidth: 75, format: 'string' },
        { id: 'columnOpciones', align: 'center', label: 'Opciones', minWidth: 75, format: 'string' }
    ];

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig?null:renderPagination()}
                </Paper>
            </Grid>
        )
    }


    const handleClickDownloadPdf = (idGuiaTransportista) => {
        setIsFinished(1);
        setSelectedGuiaId(idGuiaTransportista)
        setOpenGuiasDialog(true)
    };

    const handleClickDetalis = (idGuiaTransportista) => {
        setSelectedGuiaId(idGuiaTransportista)
        setOpenDetailDialog(true)
    }


    const renderActionTableButtons = (guia) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton aria-label="edit" color="primary" onClick={() => handleClickDetalis(guia.idGuiaTransportista)}>
                            <ListIcon fontSize="medium" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton aria-label="edit" color="refresh" onClick={() => handleClickDownloadPdf(guia.idGuiaTransportista)}>
                            <PictureAsPdfIcon fontSize="medium" />
                        </IconButton>
                    </Grid>
                </ThemeProvider>
            </Grid>
        )
    }

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                    {isDataWaitig?<LinearProgress color="success" />:null}
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(guiaData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (guiaData.map(guia => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${guia.id}`}>
                                            <TableCell align='center'>
                                                {guia.codigo.serie}-{guia.codigo.codigo}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {guia.fechaRegistro}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {guia.remitente.ruc} - {guia.remitente.razonSocial}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {guia.destinatario.ruc} - {guia.destinatario.razonSocial}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {guia.estado == 1 ? "ACTIVO" : "ANULADO"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                S/.{guia.precioTotal}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(guia)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig?<LinearProgress color="success" />:null}
                <Table>
                    {(guiaData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (guiaData.map(guia => (
                            <TableBody key={`${guia.id}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Nro. Guia Transp.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{guia.codigo.serie}-{guia.codigo.codigo}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Fecha:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{guia.fechaRegistro}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Remitente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{guia.remitente.ruc} - {guia.remitente.razonSocial}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Destinatario:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {guia.destinatario.ruc} - {guia.destinatario.razonSocial}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Estado:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {guia.estado == 1 ? "ACTIVO" : "ANULADO"} </div>
                                        </div>
                                        {renderActionTableButtons(guia)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    const handleGuias = async () => {
        try {
            setIsFinished(2);
            const responseData = await GuiaService.getDetalle(tokenActual, '', selectedGuiaId);
            setGuiasDownload(
                <Button variant="contained" color="success">
                    <PDFGenerator
                        setIsFinished={setIsFinished}
                        documento={<DocumentGuia data={responseData} />}
                        fileName={"Guia  - " + selectedGuiaId + ".pdf"}
                    />
                </Button>
            )
            setIsFinished(3);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderGuiasObject = () => {
        return (
            <DownloadPdfDialog
                setOpen={setOpenGuiasDialog}
                deleteFunction={handleGuias}
                open={openGuiasDialog}
                title={'Descarga de Guia de Transportista'}
                contentMessage=''
                pdfDownload={guiasDownload}
                isFinished={isFinished}
            />
        )
    }

    const renderDetailsDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openDetailDialog} onClose={() => setOpenDetailDialog(false)}>
                <GuiaTransportistaDetail
                    token={tokenActual}
                    guiaTransportistaId={selectedGuiaId}
                    setDialogOpen={setOpenDetailDialog}
                    isSmUp={props.isSmUp}
                    manageAlert={manageAlert}
                >
                </GuiaTransportistaDetail>
            </Dialog>
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {renderGuiasObject()}
            {renderDetailsDialog()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
    );
};

export default GuiaTransportista;