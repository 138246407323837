const API_BASE_URL = `${process.env.REACT_APP_BACKEND_ALMACEN_URL}`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if (statusCode === 200)
        return await response.json();
    if (statusCode === 201)
        return await response.json();
    else if (statusCode === 204)
        return null;
    else if (statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if (statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if (statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if (statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const EnviosService = {

    getOrigenes: async (token) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ingreso-mercaderia/origen`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },



    getEnvios: async (token, page, fecha, origen) => {
        try {
            const fechaFilter = fecha !== '' ? `&fechaRegistro=${fecha}` : ''
            const origenFilter = origen !== null && origen !== '' ? `&sucursal=${origen}` : ''

            const allfilters = fechaFilter + origenFilter


            const response = await fetch(`${API_BASE_URL}/envios-interprovinciales?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },


    updateSalida: async (token, idManifiesto) => {
        try {

            const response = await fetch(`${API_BASE_URL}/envios-interprovinciales/salida/${idManifiesto}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getDestinos: async (token, idManifiesto) => {
        try {
            const response = await fetch(`${API_BASE_URL}/envios-interprovinciales/manifiesto/destino/${idManifiesto}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },


    getManifiesto: async (token, idManifiesto, destino) => {
        try {
            const destinoFilter = destino !== null && destino !== '' ? `?destino=${destino}` : ''

            const response = await fetch(`${API_BASE_URL}/envios-interprovinciales/manifiesto/${idManifiesto}${destinoFilter}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)

            // return {
            //         "idManifiesto": "22689",
            //         "fechaRegistro": "13/01/2024",
            //         "marca": "ISUZU",
            //         "nroPlaca": "C5J-854",
            //         "nombreChofer": "JESUS",
            //         "licenciaChofer": "D-48662732",
            //         "sucursal": "Lima",
            //         "totalPrecio": "4000.00",
            //         "mercaderia": [
            //             {
            //                 "idMercaderia": "313692",
            //                 "fechaRegistro": "13/01/2024",
            //                 "codigos": {
            //                     "serie": "008",
            //                     "codigo": "140266"
            //                 },
            //                 "razonSocialDestinatario": "TIENDAS APOLO S.A.C.",
            //                 "cantidad": "579",
            //                 "precio": "3500000.00",
            //                 "destino": "Trujillo",
            //                 "pago": "No",
            //                 "descripcion":"1 CAJAS C/ ART. PLEGABLE - 578 CAJAS C/ SILLA PLEGABLE Y ART. P/ EL HOGAR -"
            //             },
            //             {
            //                 "idMercaderia": "313606",
            //                 "fechaRegistro": "12/01/2024",
            //                 "codigos": {
            //                     "serie": "008",
            //                     "codigo": "140182"
            //                 },
            //                 "razonSocialDestinatario": "DISTRIBUIDORA ROYER S.A.C.",
            //                 "cantidad": "100",
            //                 "precio": "5000000.00",
            //                 "destino": "Trujillo",
            //                 "pago": "No",
            //                 "descripcion":"10 CAJAS C/ EXTRA THINNER BC-1005 ENV. X 9 X 3.25 LTS - 30 CAJAS C/ THINNER ACRIL. ACL-305 ENV. ECON. 2.70 LT - 60 CAJAS C/ THINNER ACRIL. AC-105 ENV. ECON. 2.70 LTS -"
            //             }
            //         ]
            //     }

        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    create: async(token, envio) => {
        try {
            const response = await fetch(`${API_BASE_URL}/envios-interprovinciales/registrar`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(envio)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    update: async(token, envio) => {
        try {
            const response = await fetch(`${API_BASE_URL}/envios-interprovinciales/actualizar`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(envio)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    
    getMercaderia: async (token, page, origen, fecha, rucDestinatario) => {
        try {
            //const origenFilter = origen !== '' ? `&origen=${origen}` : ''
            const fechaFilter = fecha !== '' ? `&fechaIngreso=${fecha}` : ''
            const rucFilter = rucDestinatario !== '' ? `&rucDestinatario=${rucDestinatario}` : ''

            const allfilters = fechaFilter + rucFilter

            const response = await fetch(`${API_BASE_URL}/caracteristicas/activas/${origen}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
            
            // return {
            //     "content": [
            //         {
            //             "idCaracteristicas": "537000",
            //             "fechaIngreso": "13/01/2024",
            //             "destino": "Trujillo",
            //             "rucDestinatario": "10402584446",
            //             "razonSocial": "FLORIAN ESPINOZA  YESICCA PAOLITA",
            //             "cantidad": "2",
            //             "nombre": "CAJAS C/ GRIFERIA Y ACC. ",
            //             "total": "16"
            //         },
            //         {
            //             "idCaracteristicas": "536999",
            //             "fechaIngreso": "13/01/2024",
            //             "destino": "Trujillo",
            //             "rucDestinatario": "10449914118",
            //             "razonSocial": "ESPINOZA SEVILLANO JUDITH ELISA",
            //             "cantidad": "3",
            //             "nombre": "CAJAS C/ MATERIAL MEDICO ",
            //             "total": "30"
            //         }
            //     ],
            //     "totalPages": 1
            // }
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
}

export default EnviosService;