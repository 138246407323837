const API_BASE_URL = `${process.env.REACT_APP_BACKEND_SEGURIDAD_URL}/login`;

const Login = { 
   
    login: async (credentials) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials)
            });
            if(response.status === 200 ) 
                return await response.json();
            else
                throw new Error(`Error al obtener datos, contacte al administrador - ${response.status}`);
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    
}

export default Login;