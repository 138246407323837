const API_BASE_URL = `${process.env.REACT_APP_BACKEND_COMERCIAL_URL}/empleados`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if(statusCode === 200) 
        return await response.json();
    else if(statusCode === 204)
        return null;
    else if(statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if(statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if(statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if(statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const EmpleadosService = {
    getEmpleadosCombo: async (token, filterByActive) => {
        try {
            const response = await fetch(`${API_BASE_URL}/combo?filterByActive=${filterByActive}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getEmpleados: async (token, page, nroDocIdentidad, nombres, apellidos, areaId) => {
        try {
            const nroDocIdentidadFilter = nroDocIdentidad !== '' ? `&nroDocIdentidad=${nroDocIdentidad}` : ''
            const nombresFilter = nombres !== '' ? `&nombres=${nombres}` : ''
            const apellidosFilter = apellidos !== '' ? `&apellidos=${apellidos}` : ''
            const areaIdFilter = areaId !== null ? `&areaId=${areaId}` : ''
            const allfilters = nroDocIdentidadFilter + nombresFilter + apellidosFilter + areaIdFilter
            const response = await fetch(`${API_BASE_URL}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getEmpleadoById: async (token, empleadoId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/id/${empleadoId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    saveEmpleado: async (token, empleado) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(empleado)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    updateEmpleado: async (token, empleado) => {
        try {
            const response = await fetch(`${API_BASE_URL}/actualizar`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(empleado)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },


    getChoferes: async (token) => {
        try {

            const response = await fetch(`${API_BASE_URL}/choferes`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)

            // return [
            //     {
            //         "id": "1",
            //         "nombre": "CLARK IRVING ISLA BALAREZO"
            //     },
            //     {
            //         "id": "2",
            //         "nombre": "BRAYAN NEYRA URIARTE"
            //     },
            //     {
            //         "id": "4",
            //         "nombre": "JESUS"
            //     }
            // ]
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
}

export default EmpleadosService;