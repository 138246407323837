import React, { useState, Fragment} from "react";
//Imports material-ui
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


export default function UsuarioModalFinalizar(props) {

    const handleClose = () => {
        props.setOpenFinalizar(false);
    };
    
    
    return(
        <Fragment>
            <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        Usuario: {props.usuarioFinalizar}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        Contraseña temporal: {props.pwdFinalizar}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="success" >Cerrar</Button>
            </DialogActions>
        </Fragment>
    );
}