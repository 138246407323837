import React, { useState, Fragment} from "react";
//Imports material-ui
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//Servicios
import { SubModulo1Service } from "../../services/submodulo1";
import RolServices from "../../services/roles";

//Componente
import Alert from '../../components/Alert'

export default function SubModulo1ModalEliminar(props) {

    const subModulo1Service = new SubModulo1Service();

    const [openAlertError, setOpenAlertError] = useState(false);

    const deleteRolById = async () => {
        const tokenActual = window.localStorage.getItem('loggedDashboard').slice(1,-1)
        try {
            await RolServices.deleteRolById(props.token, props.id);
            setOpenAlertError(false)
            props.setOpenAlertOk(true);
            props.setMsjAlertExitoso("Eliminado correctamente")
            props.setSeverityAlert('warning')
            props.reloadAllEntidad(tokenActual, 0, "", "", "")
            handleClose()
        }
        catch (error) {
            setOpenAlertError(true)
        }
    };

    
    const handleClose = () => {
        props.setOpenDelete(false);
    };
    
    
    return(
        <Fragment>
            <DialogTitle>Eliminar rol </DialogTitle>
            <DialogContent>
                ¿Está seguro que desea eliminar el rol seleccionado?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={deleteRolById} variant="contained" >Aceptar</Button>
            </DialogActions>
            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje="Error al eliminar" severity="error"/>
        </Fragment>
    );
}