import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import AutoComplete from '../../components/AutoComplete';

import DireccionesService from '../../services/direcciones';

const DireccionComponent = ({
    token,
    manageAlert,
    direccion,
    onDireccionChange,
    departamentos,
    indexDireccion,
    onDelete,
    //direccionCompletedState,
}) => {
    const [direccionState, setDireccionState] = useState(direccion)

    //const [departamentos, setDepartamentos] = useState([])
    const [inputDepartamento, setInputDepartamento] = useState('')

    const [provincias, setProvincias] = useState([])
    const [inputProvincia, setInputProvincia] = useState('')

    const [distritos, setDistritos] = useState([])
    const [inputDistrito, setInputDistrito] = useState('')

    useEffect(() => {
        if ((direccionState.departamento) && (direccionState.departamento.id !== null)) {
            findDataForFields(token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFieldChange = (values) => {
        setDireccionState(prevState => ({
            ...prevState,
            ...values
        }))

        onDireccionChange({
            ...direccionState,
            ...values
        })
    }

    const findDataForFields = async (token) => {
        try {
            const provinciasResponse = await DireccionesService.getProvinciasByDepartamento(token, direccionState.departamento.id);
            setProvincias(provinciasResponse);

            const distritosResponse = await DireccionesService.getDistritosByProvincia(token, direccionState.provincia.id)
            setDistritos(distritosResponse)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const handleDelete = () => {
        onDelete(direccionState.id);
    }

    const findProvinciasByDepartamento = async (token, departamentoId) => {
        try {
            const responseData = await DireccionesService.getProvinciasByDepartamento(token, departamentoId);
            setProvincias(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const findDistritosByProvincia = async (token, provinciaId) => {
        try {
            const responseData = await DireccionesService.getDistritosByProvincia(token, provinciaId);
            setDistritos(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/

    function handleOptionLabelNombre(objeto) {
        return objeto.nombre
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    const handleDepartamentoSelectedChange = (event, newValue) => {
        setInputDistrito('')
        setDistritos([])
        setInputProvincia('')
        setProvincias([])
        handleFieldChange({
            distrito: null,
            provincia: null,
            departamento: newValue ? newValue : null
        })
        if (newValue) {
            findProvinciasByDepartamento(token, newValue.id)
        }
    }

    const handleInputDepartamentoChange = (event, newValue) => {
        setInputDepartamento(newValue)
    }

    const renderDepartamento = () => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `direccion${direccionState.id}-departamentoBox`,
                    direccionState.departamento,
                    handleDepartamentoSelectedChange,
                    inputDepartamento,
                    handleInputDepartamentoChange,
                    departamentos,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Departamento',
                    'small'
                )}
            </Grid>
        )
    }

    const handleProvinciaSelectedChange = (event, newValue) => {
        setInputDistrito('')
        setDistritos([])
        handleFieldChange({
            distrito: null,
            provincia: newValue ? newValue : null
        })
        if (newValue) {
            findDistritosByProvincia(token, newValue.id)
        }
    }

    const handleInputProvinciaChange = (event, newValue) => {
        setInputProvincia(newValue)
    }

    const renderProvincia = () => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `direccion${direccionState.id}-provinciaBox`,
                    direccionState.provincia,
                    handleProvinciaSelectedChange,
                    inputProvincia,
                    handleInputProvinciaChange,
                    provincias,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Provincia',
                    'small'
                )}
            </Grid>
        )
    }

    const handleDistritoSelectedChange = (event, newValue) => {
        handleFieldChange({
            distrito: newValue ? newValue : null
        })
    }

    const handleInputDistritoChange = (event, newValue) => {
        setInputDistrito(newValue)
    }

    const renderDistrito = () => {
        return (
            <Grid item xs={12} sm={3.85} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `direccion${direccionState.id}-distritoBox`,
                    direccionState.distrito,
                    handleDistritoSelectedChange,
                    inputDistrito,
                    handleInputDistritoChange,
                    distritos,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Distrito',
                    'small'
                )}
            </Grid>
        )
    }

    const handleDireccionChange = (newValue) => {
        handleFieldChange({
            direccion: newValue
        })
    }

    const renderDireccionTextField = () => {
        return (
            <Grid item xs={10.5} sm={7} md={11} lg={11} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id={`direccion${direccionState.id}-direccionTextField`}
                    label="Direccion"
                    variant="outlined"
                    value={direccionState.direccion}
                    onChange={(event) => handleDireccionChange(event.target.value)}
                    size='small'
                    fullWidth
                >
                </TextField>
            </Grid>
        )
    }

    return (
        <Grid style={{ padding: '0px', margin: '0px' }} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <Divider textAlign="left">
                    Dirección {indexDireccion}
                </Divider>
            </Grid>
            {renderDepartamento()}
            {renderProvincia()}
            {renderDistrito()}
            {renderDireccionTextField()}
            <Grid item xs={1.5} sm={1.15} md={1} lg={1} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <IconButton aria-label="add-address" size="medium" color="error" onClick={handleDelete}>
                    <RemoveCircleOutlineIcon fontSize="medium" />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default DireccionComponent