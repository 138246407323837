import React, { useState, Fragment} from "react";
//Imports material-ui
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

//Servicios
import UsuarioServices from "../../services/user";

//Componente
import Alert from '../../components/Alert'

export default function UsuarioModalEliminar(props) {

    const [openAlertError, setOpenAlertError] = useState(false);

    let usuarioResponse = null;
    let usuarioError = null;

    async function eliminarEntidad() {
        const tokenActual = window.localStorage.getItem('loggedDashboard').slice(1,-1)
        try{
            usuarioResponse =  await UsuarioServices.deleteUsuario(tokenActual, props.id);

        }catch(error){
            usuarioError = (error.message)
        }
        if(usuarioError==null){
            setOpenAlertError(false)
            props.setOpenAlertOk(true);
            props.setMsjAlertExitoso("Eliminado correctamente")
            props.setSeverityAlert('warning')
            props.reloadAllEntidad(tokenActual, 0,'','','') 
            handleClose()
        }else{
            setOpenAlertError(true)
        }
        
    }

    
    const handleClose = () => {
        props.setOpenDelete(false);
    };
    
    
    return(
        <Fragment>
            <DialogTitle>Eliminar Usuario</DialogTitle>
            <DialogContent>
                ¿Está seguro que desea eliminar el usuario?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={eliminarEntidad} variant="contained" >Aceptar</Button>
            </DialogActions>
            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje="Error al eliminar" severity="error"/>
        </Fragment>
    );
}