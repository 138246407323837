import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SideBar from './SideBar.jsx'
import { BrowserRouter as Router, Route , Routes} from 'react-router-dom'
import SubModulo1 from './modulo1/SubModulo1.jsx'
import Roles from "./seguridad-roles/Roles.jsx";
import Home from './Home'
import Header from './Header';
import Usuarios from "./seguridad-usuarios/Usuarios.jsx";
import Unidades from "./comercial-unidades/Unidades.jsx";
import Empleados from "./comercial-empleados/Empleados.jsx";
import Clientes from "./comercial-clientes/Clientes.jsx";
import Auditoria from "./seguridad-auditoria/Auditoria.jsx";
import Mercaderia from "./almacen-mercaderia/Mercaderia.jsx";
import GuiaTransportista from "./almacen-guia/GuiaTransportista.jsx";
import EnviosInterprovinciales from "./almacen-envios-interprovinciales/EnviosInterprovinciales.jsx";
import Cuentas from "./financiera-cuenta/Cuentas.jsx";
import Ingresos from "./financiera-ingresos/Ingresos.jsx";

let theme = createTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#009900',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: theme.spacing(1),
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: theme.palette.common.white,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(255,255,255,0.15)',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#4fc3f7',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
            minWidth: 'auto',
            marginRight: theme.spacing(2),
            '& svg': {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
    },
  };
  
  const drawerWidth = 0;

  
export default function Dashboard(props){
    const [mobileOpen, setMobileOpen] = useState(false);
    const [cantidadCart,setCantidadCart]=useState(0)

    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const handleIsLogin = () => {
      props.setIsLogin(false)
    }

  
    return (
      <ThemeProvider theme={theme}>
        
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        
          <CssBaseline />
          
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {/* {(isSmUp) ? null : (
              <SideBar
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            )} */}
            {/* <SideBar
              PaperProps={{ style: { width: drawerWidth } }}
              sx={{ display: { sm: 'block', xs: 'none' } }}
              /> */}
              <SideBar
                PaperProps={{ style: { width: 200 } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{ display: { sm: 'block', xs: 'block' } }}
              />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header onDrawerToggle={handleDrawerToggle} handleIsLogin={handleIsLogin}  isSmUp={isSmUp}/>
            <Box component="main" sx={{ flex: 1, py: 2, px: 3, bgcolor: '#eaeff1' }}>
                <Routes>
                    <Route path={'/'} exact element={<Home mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}/>} />
                    <Route path={'/modulo1-dashboard/submodulo1'} exact element={<SubModulo1 mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Roles'} exact element={<Roles mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Usuarios'} exact element={<Usuarios mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Unidades'} exact element={<Unidades mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Empleados'} exact element={<Empleados mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Clientes'} exact element={<Clientes mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Auditoria'} exact element={<Auditoria mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Mercaderia'} exact element={<Mercaderia mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Guias%20Transportista'} exact element={<GuiaTransportista mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Envios%20Interprovinciales'} exact element={<EnviosInterprovinciales mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Cuentas'} exact element={<Cuentas mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                    <Route path={'/dashboard/modulos/Ingresos'} exact element={<Ingresos mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle}  isSmUp={isSmUp}/>}/>
                </Routes>              
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }