import { useState, useEffect, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MercaderiaService from '../../services/mercaderia';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';

const MercaderiaDetail = ({
    title,
    token,
    mercaderiaId,
    setDialogOpen,
    isSmUp,
    manageAlert
}) => {

    const [caracteristicas, setCaracteristicas] = useState([])


    const tableColumns = [
        { id: 'columnCantidad', align: 'center', label: 'Cantidad', minWidth: 75, format: 'string' },
        { id: 'colummnTipoMercaderia', align: 'center', label: 'Tipo Mercaderia', minWidth: 75, format: 'string' },
        { id: 'columnDescripcion', align: 'center', label: 'Descripción', minWidth: 75, format: 'string' },
        { id: 'columnaPeso', align: 'center', label: 'Peso', minWidth: 75, format: 'string' },
        { id: 'columnPrecioUnit', align: 'center', label: 'Precio Unit.', minWidth: 75, format: 'string' },
        { id: 'columnTotal', align: 'center', label: 'Total', minWidth: 75, format: 'string' }
    ];

    useEffect(() => {
        findMercaderiaById(token, mercaderiaId)
    }, [])

    const findMercaderiaById = async (token, mercaderiaId) => {
        try {
            const mercaderiaData = await MercaderiaService.getMercaderiaById(token, mercaderiaId);
            if (mercaderiaData) {
                setCaracteristicas(mercaderiaData.caracteristicas || [])
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }


    const renderTitle = () => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>Detalle de Mercadería</DialogTitle>
        )
    }


    const renderTable = () => {
        return (
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((column) => (
                                <TableCell
                                    key={`${column.id}-${column.label}`}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            (caracteristicas === null) ?
                                (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                    <TableCell align='center'>---</TableCell>
                                </TableRow>)
                                :
                                (caracteristicas.map(caracteristica => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={`${caracteristica.id}`}>
                                        <TableCell align='center'>
                                            {caracteristica.cantidad || "---"}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {caracteristica.tipoMercaderia?.nombre || "---"}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {caracteristica.nombre || "---"}
                                        </TableCell>
                                        <TableCell align='center'>
                                            {caracteristica.peso} {caracteristica.unidadMedida.id}
                                        </TableCell>
                                        <TableCell align='center'>
                                            S/. {caracteristica.precioUnitario || "---"}
                                        </TableCell>
                                        <TableCell key="options" align="center">
                                            S/. {caracteristica.total || "---"}
                                        </TableCell>
                                    </TableRow>
                                )))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                <Table>
                    {(caracteristicas === null) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>) :
                        (caracteristicas.map(caracteristica => (
                            <TableBody key={`${caracteristica.id}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Cantidad:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.cantidad}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Tipo Mercadería:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {caracteristica.tipoMercaderia?.nombre}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Descripción:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.nombre}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Peso:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.peso} {caracteristica.unidadMedida.id}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Precio Unit.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {caracteristica.precioUnitario}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Total:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{caracteristica.total}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };


    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0rem 1.25rem 0.5rem 1.25rem' }}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(isSmUp) ? (renderTable()) : (renderCards())}
                </Paper>
            </DialogContent>
        )
    }

    return (
        <Fragment>
            {renderTitle(title)}
            {renderContent()}
        </Fragment>
    )
}

export default MercaderiaDetail