import { useState, useEffect, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';

import AutoComplete from '../../components/AutoComplete';
import DireccionComponent from './ClienteDirecciones';

import ClientesService from '../../services/clientes';
import TiposDocumentoService from '../../services/tiposdocumento';
import DireccionesService from '../../services/direcciones';

const ClienteCreateUpdate = ({
    title,
    token,
    isCreateDialog,
    clienteId,
    setDialogOpen,
    reloadData,
    flagForReloadData,
    isSmUp,
    manageAlert
}) => {
    const [clienteToUpdate, setClienteToUpdate] = useState(null)

    const [tiposDocumento, setTiposDocumento] = useState([])
    const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState(null)
    const [inputTipoDocumento, setInputTipoDocumento] = useState('')

    const [docIdent, setDocIdent] = useState('')
    const [razonSocial, setRazonSocial] = useState('')
    const [celular, setCelular] = useState('')
    const [correo, setCorreo] = useState('')
    const [isCredito, setIsCredito] = useState(false)

    const buildDireccionesWithOneEmpty = () => {
        return [
            {
                id: uuidv4(),
                direccion: '',
                distrito: null,
                provincia: null,
                departamento: null,
                estado: true,
                esNueva: true
            }
        ]
    }

    const [direcciones, setDirecciones] = useState(buildDireccionesWithOneEmpty())
    // eslint-disable-next-line no-unused-vars
    const [nroMaxDirecciones, setNroMaxDirecciones] = useState(15)
    const [departamentos, setDepartamentos] = useState([])

    useEffect(() => {
        getTiposDocumento(token)
        getDepartamentos(token)
        if (!isCreateDialog) {
            findClienteById(token, clienteId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (clienteToUpdate) {
            fillFormFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clienteToUpdate])

    const getDistritoByNombre = async (token, distritoNombre, provinciaNombre) => {
        try {
            const distritoResponse = await DireccionesService.getDistritoByNombre(token, distritoNombre, provinciaNombre);
            return distritoResponse;
        }
        catch (error) {
            manageAlert('error', error.message, true)
            return null
        }
    }

    const findClienteById = async (token, clienteId) => {
        try {
            const clienteData = await ClientesService.getClienteById(token, clienteId);
            if (clienteData) {
                const direccionesCompletas = await Promise.all(clienteData.direcciones.map(async (direccion) => {
                    const distritoData = await getDistritoByNombre(token, direccion.distrito.nombre, direccion.provincia.nombre);
                    const direccionCompleta = {
                        id: direccion.id,
                        direccion: direccion.direccion,
                        distrito: null,
                        provincia: null,
                        departamento: null,
                        estado: direccion.estado,
                        esNueva: false
                    }
                    if (distritoData) {
                        direccionCompleta.distrito = {
                            id: distritoData.id,
                            nombre: distritoData.nombre
                        }
                        direccionCompleta.provincia = {
                            id: distritoData.provincia.id,
                            nombre: distritoData.provincia.nombre
                        }
                        direccionCompleta.departamento = {
                            id: distritoData.provincia.departamento.id,
                            nombre: distritoData.provincia.departamento.nombre
                        }
                    }
                    return direccionCompleta
                }))
                clienteData.direcciones = direccionesCompletas
            }
            setClienteToUpdate(clienteData ? clienteData : null)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const fillFormFields = () => {
        setTipoDocumentoSelected(clienteToUpdate.tipoDocumento || null)
        setDocIdent(clienteToUpdate.nroDocumento || '')
        setRazonSocial(clienteToUpdate.razonSocial || '')
        setCelular(clienteToUpdate.telefono || '')
        setCorreo(clienteToUpdate.correo || '')
        setIsCredito(clienteToUpdate.credito || false)
        clienteToUpdate.direcciones && clienteToUpdate.direcciones.length > 0 ?
            setDirecciones(
                clienteToUpdate.direcciones
                /*clienteToUpdate.direcciones.map(direccion => ({
                    ...direccion,
                    departamento: searchLocalidadToSelect(departamentos, direccion.departamento.nombre),
                    esNueva: false
                }))*/
            ) :
            setDirecciones(buildDireccionesWithOneEmpty())
    }

    const getTiposDocumento = async (token) => {
        try {
            const responseData = await TiposDocumentoService.getTiposDocumento(token);
            setTiposDocumento(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const getDepartamentos = async (token) => {
        try {
            const responseData = await DireccionesService.getDepartamentos(token);
            setDepartamentos(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderTitle = (title) => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>{title}</DialogTitle>
        )
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if (/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const changeStateOnlyIfLetterOrSpace = (newValue, stateFunction) => {
        const letters = /^[a-zA-Z0-9\s.\-_&+]*$/
        if (letters.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/

    function handleOptionLabelNombre(objeto) {
        return objeto.nombre
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    const handleTipoDocumentoSelectedChange = (event, newValue) => {
        setTipoDocumentoSelected(newValue ? newValue : null)
        //setDocIdent('')
    }

    const handleInputTipoDocumentoChange = (event, newValue) => {
        setInputTipoDocumento(newValue)
    }

    const renderTipoDocumento = () => {
        return (
            <Grid item xs={6.5} sm={6.5} md={2.75} lg={2.75} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'tipodocumento-box',
                    tipoDocumentoSelected,
                    handleTipoDocumentoSelectedChange,
                    inputTipoDocumento,
                    handleInputTipoDocumentoChange,
                    tiposDocumento,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Tipo Doc. Ident.',
                    'small'
                )}
            </Grid>
        )
    }

    const renderDocIdentTextField = () => {
        return (
            <Grid item xs={5.5} sm={5.5} md={2.25} lg={2.25} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="docidentidad-textfield"
                    label="Doc. Ident."
                    variant="outlined"
                    value={docIdent}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setDocIdent)}
                    size='small'
                    fullWidth
                    disabled={tipoDocumentoSelected === null}>
                </TextField>
            </Grid>
        )
    }

    const renderRazonSocialTextField = () => {
        return (
            <Grid item xs={12} sm={12} md={7} lg={7} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="razonSocial-textfield"
                    label="Nombres o Razón Social"
                    variant="outlined"
                    value={razonSocial}
                    onChange={(event) => changeStateOnlyIfLetterOrSpace(event.target.value, setRazonSocial)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderCelularTextField = () => {
        return (
            <Grid item xs={3} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="celular-textfield"
                    label="Celular"
                    variant="outlined"
                    value={celular}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setCelular)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderCorreoTextField = () => {
        return (
            <Grid item xs={7} sm={7} md={7} lg={7} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="correo-textfield"
                    label="Correo"
                    variant="outlined"
                    value={correo}
                    onChange={(event) => setCorreo(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleCreditoCheckbox = (event) => {
        setIsCredito(event.target.checked);
    }

    const renderCreditoCheckbox = () => {
        return (
            <Grid item xs={2} sm={2} md={2} lg={2} display={'flex'} alignItems='center' justifyContent={isSmUp ? 'left' : 'left'} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <FormControlLabel
                    label='Crédito'
                    control={
                        <Checkbox
                            id={'iscredito-checkbox'}
                            checked={isCredito}
                            onChange={handleCreditoCheckbox}
                            size='small'
                        //inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                />
            </Grid>
        )
    }

    const handleDireccionChange = (newDireccion, direccionId) => {
        const nuevasDirecciones = direcciones.map(direccion => {
            if (direccion.id === direccionId) {
                return newDireccion;
            }
            return direccion;
        })
        setDirecciones(nuevasDirecciones)
    }

    const handleDeleteDireccion = (direccionId) => {
        const direccionesActivas = direcciones.filter(direccion => direccion.estado)
        if (direccionesActivas.length === 1) {
            manageAlert('warning', 'Se debe mantener activa al menos una dirección por cliente', true)
        }
        else {
            const nuevasDirecciones = direcciones.map(direccion => {
                if (direccion.id === direccionId) {
                    if (direccion.esNueva) {
                        return null
                    }
                    else {
                        const direccionOriginal = clienteToUpdate.direcciones.find(direccionOriginal => direccionOriginal.id === direccion.id)
                        return { ...direccionOriginal, estado: false }
                    }
                }
                return direccion
            })

            setDirecciones(nuevasDirecciones.filter(direccion =>
                direccion !== null
            ))
        }
    }

    const renderDirecciones = () => {
        let indice = 0
        return (
            direcciones.map((direccion) => {
                if ((indice < nroMaxDirecciones) && (direccion.estado)) {
                    indice = indice + 1
                    return (
                        <DireccionComponent
                            key={direccion.id}
                            token={token}
                            manageAlert={manageAlert}
                            direccion={direccion}
                            onDireccionChange={(newDireccion) => handleDireccionChange(newDireccion, direccion.id)}
                            departamentos={departamentos}
                            indexDireccion={indice}
                            onDelete={() => handleDeleteDireccion(direccion.id)}
                        />
                    )
                }
                return null
            })
        )
    }

    const handleAddDireccionButton = () => {
        const direccionesActivas = direcciones.filter(direccion => direccion.estado)
        if (direccionesActivas.length < nroMaxDirecciones) {
            const newEmptyDireccion = buildDireccionesWithOneEmpty()[0]
            setDirecciones(prevData => ([
                ...prevData,
                newEmptyDireccion
            ]))
        }
        else {
            manageAlert('warning', `El límite de direcciones por cliente es de ${nroMaxDirecciones}`, true)
        }
    }

    const renderAddDireccionButton = () => {
        return (
            <Grid container style={{ padding: '0.5rem 0rem 0rem 0rem', margin: '0px 0px 0px 0px' }}>
                <Grid item style={{ paddingLeft: '0.3rem' }}>
                    <IconButton onClick={() => handleAddDireccionButton()} aria-label="add-address" size="large" color="primary">
                        <PlaylistAddIcon fontSize="large" />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0.5rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {renderTipoDocumento()}
                    {renderDocIdentTextField()}
                    {renderRazonSocialTextField()}
                    {renderCelularTextField()}
                    {renderCorreoTextField()}
                    {renderCreditoCheckbox()}
                    {renderDirecciones()}
                    {renderAddDireccionButton()}
                </Grid>
            </DialogContent>
        )
    }

    const handleCloseButton = () => {
        setDialogOpen(false);
    };

    const buildRequestBody = () => {
        const requestBody = {
            nroDocumento: docIdent,
            tipoDocumentoId: tipoDocumentoSelected.id,
            razonSocial: razonSocial,
            telefono: celular,
            correo: correo,
            credito: isCredito,
            direcciones: direcciones.map(direccion => {
                return {
                    id: direccion.esNueva ? null : direccion.id,
                    direccion: direccion.direccion,
                    distritoId: direccion.distrito.id,
                    estado: direccion.esNueva ? true : direccion.estado
                }
            })
        }
        return requestBody
    }

    const saveCliente = async (token, isCreate, reloadData, flagForReloadData, setDialogOpen) => {
        try {
            const response = ((isCreate) ? (await ClientesService.saveCliente(token, buildRequestBody())) : (await ClientesService.updateCliente(token, buildRequestBody())))
            if (response) {
                manageAlert('success', ('Cliente ' + (isCreate ? 'creado' : 'actualizado') + ' exitosamente'), true)
                reloadData(!flagForReloadData)
                setDialogOpen(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const handleSaveButton = () => {
        const incompleteDireccion = direcciones.some(direccion =>
            direccion.distrito === null ||
            (direccion.direccion === null || direccion.direccion === '')
        )

        if (
            tipoDocumentoSelected !== null
            && docIdent !== ''
            && razonSocial !== ''
            // && celular !== ''
            // && correo !== ''
            && !incompleteDireccion
        ) {
            saveCliente(token, isCreateDialog, reloadData, flagForReloadData, setDialogOpen)
        }
        else {
            manageAlert('warning', 'Llenar los campos correctamente', true)
        }
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={handleCloseButton} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={handleSaveButton} variant="contained" disabled={false} >Guardar</Button>
            </DialogActions>
        )
    }

    return (
        <Fragment>
            {renderTitle(title)}
            {renderContent()}
            {renderActionButtons()}
        </Fragment>
    )
}

export default ClienteCreateUpdate