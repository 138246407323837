import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Dialog from '@mui/material/Dialog'

import CustomizeSnackBar from '../../components/Snackbar';
import MercaderiaCreateUpdate from './MercaderiaCreateUpdate';
import DeleteObjectDialog from '../../components/DeleteObjectDialog';
import DateSelector from '../../components/DateSelector';
import MercaderiaDetail from './MercaderiaDetail';

import MercaderiaService from '../../services/mercaderia';
import LinearProgress from '@mui/material/LinearProgress';
import ListIcon from '@mui/icons-material/List';

import AutoComplete from '../../components/AutoComplete';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Mercaderia = (props) => {
    const [mercaderiasData, setMercaderiasData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [origenes, setOrigenes] = useState([])
    const [origenSelectedFilter, setOrigenSelectedFilter] = useState(null)
    const [origenFilterInput, setOrigenFilterInput] = useState('')

    const [destinos, setDestinos] = useState([])
    const [destinoSelectedFilter, setDestinoSelectedFilter] = useState(null)
    const [destinoFilterInput, setDestinoFilterInput] = useState('')

    const [fechaFilter, setFechaFilter] = useState('')
    const [docRemitenteFilter, setDocRemitenteFilter] = useState('')
    const [razonSocialRemitenteFilter, setRazonSocialRemitenteFilter] = useState('')
    const [docDestinatarioFilter, setDocDestinatarioFilter] = useState('')
    const [razonSocialDestinatarioFilter, setRazonSocialDestinatarioFilter] = useState('')


    const [activateFilters, setActivateFilters] = useState(false)
    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    const [titleDialog, setTitleDialog] = useState('');
    const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState(false);
    const [isCreateDialog, setIsCreateDialog] = useState(true);
    const [isGeneradoGtSelect, setIsGeneradoGtSelect] = useState(0);
    const [selectedMercaderiaId, setSelectedMercaderiaId] = useState(null)

    const [tokenActual, setTokenActual] = useState('');

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const[isDataWaitig, setIsDataWaiting] = useState(true);
    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Mercaderia;

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllClientes(
            loggedDashboardJSON,
            currentPage - 1,
            origenSelectedFilter ? origenSelectedFilter.id : null,
            destinoSelectedFilter ? destinoSelectedFilter.id : null,
            formatDateForString(fechaFilter),
            docRemitenteFilter,
            razonSocialRemitenteFilter,
            docDestinatarioFilter,
            razonSocialDestinatarioFilter
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, activateFilters]);

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        getOrigenesAndDestinos(loggedDashboardJSON)
    }, [])

    //Cuando fecha en la base de datos es String "dd/mm/aaaa"
    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllClientes = async (token, 
        page, origenFilter, destinoFilter, fechaFilter, docRemitenteFilter, razonSocialRemitenteFilter, docDestinatarioFilter, razonSocialDestinatario
    ) => {
        setIsDataWaiting(true);
        try {

            const responseData = await MercaderiaService.getMercaderias(
                token,
                page,
                origenFilter,
                destinoFilter,
                fechaFilter,
                docRemitenteFilter,
                razonSocialRemitenteFilter,
                docDestinatarioFilter,
                razonSocialDestinatario
            );
            setMercaderiasData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const getOrigenesAndDestinos = async (token) => {
        try {
            const responseData = await MercaderiaService.getOrigenesAndDestinos(token)
            if(responseData) {
                setOrigenes(responseData.origenes)
                setDestinos(responseData.destinos)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    /*
    const changeStateOnlyIfDate = (newValue, stateFunction) => {
        const formatoFecha = /^\d{0,4}(-\d{0,2}){0,2}$/
        if(formatoFecha.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }
    */

    const handleApplyFilters = () => {
        //if(tipoUnidadSelected !== null || marcaUnidadSelected !== null || placaFilter !== '' || empleadoSelected !== null) {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
        //}
    }

    const handleClearFilters = () => {
        setOrigenSelectedFilter(null)
        setOrigenFilterInput('')
        setDestinoSelectedFilter(null)
        setDestinoFilterInput('')
        setFechaFilter('')
        setDocRemitenteFilter('')
        setRazonSocialRemitenteFilter('')
        setDocDestinatarioFilter('')
        setRazonSocialDestinatarioFilter('')
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size, disabled) => {
        return (
            <AutoComplete
                id = {id}
                value = {value}
                onChange = {onChange}
                inputValue = {inputValue}
                onInputChange = {onInputChange}
                options = {options}
                getOptionLabel = {getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width = {width}
                label = {label}
                size = {size}
                disabled={disabled}
            />
        )
    }

    const handleFechaFilter = (date) => {
        setFechaFilter(date)
    }

    const renderFechaFilter = () => {
        return (
            <Grid item xs={12} sm={3} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-filter"
                    label="Fecha"
                    selectedValue={fechaFilter}
                    minDate={"2024-01-01"}
                    maxDate={"2050-01-01"}
                    onChange={handleFechaFilter}>
                </DateSelector>
            </Grid>
        )
    }

    const renderDocRemitenteFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="docremitente-filter"
                    label="Doc. Remitente"
                    variant="outlined"
                    value={docRemitenteFilter}
                    onChange={(event) => setDocRemitenteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderRazonSocialRemitenteFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2.5} lg={2.5} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="remitenteRazonSocial-filter"
                    label="Razon Soc. Remitente"
                    variant="outlined"
                    value={razonSocialRemitenteFilter}
                    onChange={(event) => setRazonSocialRemitenteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderDocDestinatarioFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="docdestinatario-filter"
                    label="Doc. Destinatario"
                    variant="outlined"
                    value={docDestinatarioFilter}
                    onChange={(event) => setDocDestinatarioFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderRazonSocialDestinatarioFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2.5} lg={2.5} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="destinatarioRazonSocial-filter"
                    label="Razon Soc. Destinatario"
                    variant="outlined"
                    value={razonSocialDestinatarioFilter}
                    onChange={(event) => setRazonSocialDestinatarioFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={12} sm={1.75} md={1} lg={1} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/

    function handleOptionLabelNombre (objeto) {
        return objeto.nombre
    }

    const handleOrigenSelectedChange = (event, newValue) => {
        setOrigenSelectedFilter(newValue ? newValue : null)
    }

    const handleInputOrigenChange = (event, newValue) => {
        setOrigenFilterInput(newValue)
    }

    const renderOrigenFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'filter-origen-box',
                    origenSelectedFilter,
                    handleOrigenSelectedChange,
                    origenFilterInput,
                    handleInputOrigenChange,
                    origenes,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Origen',
                    'small',
                    false
                )}
            </Grid>
        )
    }

    const handleDestinoSelectedChange = (event, newValue) => {
        setDestinoSelectedFilter(newValue ? newValue : null)
    }

    const handleInputDestinoChange = (event, newValue) => {
        setDestinoFilterInput(newValue)
    }

    const renderDestinoFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'filter-destino-box',
                    destinoSelectedFilter,
                    handleDestinoSelectedChange,
                    destinoFilterInput,
                    handleInputDestinoChange,
                    destinos,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Destino',
                    'small',
                    false
                )}
            </Grid>
        )
    }
    const renderAcordion = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Ingreso Mercadería - Filtros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2 }} style={{}}>
                            <Grid item xs={12} sm={12} md={12} >
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                                    {renderOrigenFilter()}
                                    {renderDestinoFilter()}
                                </Grid>
                            </Grid>
                            {renderFechaFilter()}
                            {renderDocRemitenteFilter()}
                            {renderRazonSocialRemitenteFilter()}
                            {renderDocDestinatarioFilter()}
                            {renderRazonSocialDestinatarioFilter()}
                            {renderFilterButtons()}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                                {renderOrigenFilter()}
                                {renderDestinoFilter()}
                            </Grid>
                        </Grid>
                        {renderFechaFilter()}
                        {renderDocRemitenteFilter()}
                        {renderRazonSocialRemitenteFilter()}
                        {renderDocDestinatarioFilter()}
                        {renderRazonSocialDestinatarioFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnDocIdent', align: 'center', label: 'Fecha', minWidth: 75, format: 'string' },
        { id: 'columnOrigen', align: 'center', label: 'Origen', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Nro. Doc. Rem.', minWidth: 75, format: 'string' },
        { id: 'columnNombres', align: 'center', label: 'Remitente', minWidth: 75, format: 'string' },
        { id: 'columnDestino', align: 'center', label: 'Destino', minWidth: 75, format: 'string' },
        { id: 'columnCelular', align: 'center', label: 'Nro. Doc. Dest.', minWidth: 75, format: 'string' },
        { id: 'columnDestinatario', align: 'center', label: 'Destinatario', minWidth: 75, format: 'string' },
        { id: 'columnUsuarioRegistro', align: 'center', label: 'Usuario Registro', minWidth: 75, format: 'string' },
        { id: 'columnUsuarioUpdate', align: 'center', label: 'Usuario Actualización', minWidth: 75, format: 'string' },
        { id: 'columnAcciones', align: 'center', label: 'Opciones', minWidth: 75, format: 'string' }
    ];

    const handleDetailTableButton = (mercaderiaId) => {
        setSelectedMercaderiaId(mercaderiaId)
        setOpenDetailDialog(true)
    }

    const handleEditTableButton = (mercaderiaId, isGeneradoGt) => {
        setSelectedMercaderiaId(mercaderiaId)
        setTitleDialog('Actualizar Ingreso')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(false)
        setIsGeneradoGtSelect(isGeneradoGt);
    }

    const handleDeleteTableButton = (mercaderiaId) => {
        setSelectedMercaderiaId(mercaderiaId)
        setOpenDeleteDialog(true)
    }

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig ? null : renderPagination()}
                </Paper>
            </Grid>
        )
    }

    const renderActionTableButtons = (mercaderia) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton aria-label="edit" color="info" onClick={() => handleDetailTableButton(mercaderia.idMercaderia)}><ListIcon fontSize="medium" /></IconButton>
                    </Grid>
                    {
                        permisos.includes(3) ?
                            <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton  aria-label="edit" color="warning" onClick={() => handleEditTableButton(mercaderia.idMercaderia, mercaderia.isGeneradoGt)}><EditIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }
                    {
                        permisos.includes(4) ?
                            <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton disabled={mercaderia.isGeneradoGt != 0} aria-label="delete" color="error" onClick={() => handleDeleteTableButton(mercaderia.idMercaderia)}><DeleteIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }

                </ThemeProvider>
            </Grid>
        )
    }

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 425 }}>
                        {isDataWaitig ? <LinearProgress color="success" /> : null}
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(mercaderiasData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (mercaderiasData.map(mercaderia => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${mercaderia.idMercaderia}`}>
                                            <TableCell align='center'>
                                                {mercaderia.fechaIngreso}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.origen}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.remitente.ruc || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.remitente.razonSocial || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.destino}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.destinatario.ruc || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.destinatario.razonSocial || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.usuarioRegistro ? mercaderia.usuarioRegistro.username : "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.usuarioUpdate ? mercaderia.usuarioUpdate.username : "---"}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(mercaderia)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig ? <LinearProgress color="success" /> : null}
                <Table>
                    {(mercaderiasData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (mercaderiasData.map(mercaderia => (
                            <TableBody key={`${mercaderia.idMercaderia}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Fecha:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{mercaderia.fechaIngreso}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Origen:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {mercaderia.origen}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Nro. Doc. Rem:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {mercaderia.remitente.ruc}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Remitente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{mercaderia.remitente.razonSocial || "---"}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Destino:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {mercaderia.destino}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Nro. Doc. Dest:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {mercaderia.destinatario.ruc}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Destinatario:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{mercaderia.destinatario.razonSocial || "---"}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Usr. Reg.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {mercaderia.usuarioRegistro ? mercaderia.usuarioRegistro.username : "---"}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Usr. Act.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {mercaderia.usuarioUpdate ? mercaderia.usuarioUpdate.username : "---"}
                                            </div>
                                        </div>
                                        {renderActionTableButtons(mercaderia)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const handleAddButton = () => {
        setTitleDialog('Registrar Ingreso')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(true)
        setIsGeneradoGtSelect(0)
    }

    const renderAddButton = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item>
                    <Button color="success" onClick={handleAddButton} variant="contained">Registrar Ingreso</Button>
                </Grid>
            </Grid>
        )
    }

    
    const renderDetailsDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openDetailDialog} onClose={() => setOpenDetailDialog(false)}>
                <MercaderiaDetail
                    token={tokenActual}
                    mercaderiaId={selectedMercaderiaId}
                    setDialogOpen={setOpenDetailDialog}
                    isSmUp={props.isSmUp}
                    manageAlert={manageAlert}
                >
                </MercaderiaDetail>
            </Dialog>
        )
    }


    const renderCreateUpdateDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openCreateUpdateDialog} onClose={() => setOpenCreateUpdateDialog(false)}>
                <MercaderiaCreateUpdate
                    title={titleDialog}
                    token={tokenActual}
                    isCreateDialog={isCreateDialog}
                    mercaderiaId={selectedMercaderiaId}
                    setDialogOpen={setOpenCreateUpdateDialog}
                    reloadData={setActivateFilters}
                    flagForReloadData={activateFilters}
                    isSmUp={props.isSmUp}
                    manageAlert={manageAlert}
                    isGeneradoGtSelect={isGeneradoGtSelect}
                >
                </MercaderiaCreateUpdate>
            </Dialog>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    const handleDelete = async () => {
        try {
            await MercaderiaService.deleteById(tokenActual, selectedMercaderiaId);
            manageAlert('success', 'Mercaderia con id ' + selectedMercaderiaId + ' eliminada exitosamente', true)
            handleClearFilters()
            setOpenDeleteDialog(false)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderDeleteObject = (mercaderiaId) => {
        return (
            <DeleteObjectDialog
                setOpen={setOpenDeleteDialog}
                deleteFunction={handleDelete}
                open={openDeleteDialog}
                title={'Eliminar Mercadería'}
                contentMessage={'¿Está seguro que desea dar de baja al registro de mercadería?'}
            />
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderAcordion()}
            {/*renderFilters()*/}
            {renderData()}
            {permisos.includes(2) ? renderAddButton() : null}
            {renderCreateUpdateDialog()}
            {renderDetailsDialog()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
            {renderDeleteObject(selectedMercaderiaId)}
        </Grid>
    );
};

export default Mercaderia;