import axios from 'axios';

class SubModulo1Service{

    base_url = "https://xxxxxxx.yyyyyy"

    getAllEntidad = async (nroPag) => {

        const url = this.base_url + "/endpoint/"+nroPag
        const res = await axios.get(url).catch(function (error) {
            if (error.response) {
                return error.response;
            }
        });
        return res;

    };

    createEntidad = async (dataCreate) => {
        const url = this.base_url + "/endpoint/"
        const res = await axios.post(url,dataCreate).catch(function (error) {
            if (error.response) {
                return error.response;
            }
          });
        return res;
        
    }

    updateEntidad = async (dataUpdate, id) => {
        const url = this.base_url + "/endpoint/"+id
        const res = await axios.put(url,dataUpdate).catch(function (error) {
            if (error.response) {
                return error.response;
            }
          });
        return res;
        
    }

    deleteEntidad = async (id) => {
        const url = this.base_url + "/endpoint/delete/"+id
        const res = await axios.post(url).catch(function (error) {
            if (error.response) {
                return error.response;
            }
          });
        return res;
        
    }
    
}

export {SubModulo1Service} ;