import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox';

const CustomizeCheckBox = ({
    id,
    label,
    handleChange,
    isChecked,
    size,
    isDisabled
}) => {
    return(
        <FormControlLabel
            id={id}
            control={
                <Checkbox
                    onChange={handleChange}
                    checked={isChecked}
                    size={size}
                />
            }
            label={label}
            disabled={isDisabled}
        />
    )
}

export default CustomizeCheckBox