import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CustomizeSnackBar from '../../components/Snackbar';
import IngresosService from '../../services/ingresos';
import DateSelector from '../../components/DateSelector';
import LinearProgress from '@mui/material/LinearProgress';
import DownloadPdfDialog from '../../components/DownloadPdfDialog';
import DocumentIngresos from './DocumentIngresos'
import Button from '@mui/material/Button';
import PDFGenerator from '../../components/PDFGenerator'
import AutoComplete from '../../components/AutoComplete';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

import UsuarioServices from '../../services/user';

const Ingresos = (props) => {
    const pagadores = [
        {
            id: "CC",
            nombre: "DESTINATARIO Y CREDITO"
        },
        {
            id: "R",
            nombre: "REMITENTE"
        }
    ]
    const [pagador, setPagador] = useState(pagadores)
    const [pagadorSelected, setPagadorSelected] = useState({ id: "CC",  nombre: "DESTINATARIO Y CREDITO"});
    const [inputPagador, setInputPagador] = useState('');

    const [ingresosData, setIngresosData] = useState([]);
    const [ingresoReporte, setIngresoReporte] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    //FILTROS
    const [fechaInicioFilter, setFechaInicioFilter] = useState('')
    const [fechaFinFilter, setFechaFinFilter] = useState('')
    const [docClienteFilter, setDocClienteFilter] = useState('')
    const [razonClienteFilter, setRazonClienteFilter] = useState('')
    const [usuarioFilter, setUsuarioFilter] = useState('')

    const [activateFilters, setActivateFilters] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [tokenActual, setTokenActual] = useState('');
    const [openReporteDialog, setOpeReporteDialog] = useState(false)
    const [isDataWaitig, setIsDataWaiting] = useState(true);
    const [ingresosDownload, setIngresosDownload] = useState();
    const [isFinished, setIsFinished] = useState(1);
    const [disabledFecha, setDisabledFecha] = useState(true);

    const [disabledReporte, setDisabledReporte] = useState(true);

    const [usuarios, setUsuarios] = useState([])
    const [usuariosSeleccionados, setUsuariosSeleccionados] = useState([])

    const [mostrarFiltroUsuarios, setMostrarFiltroUsuarios] = useState(false)
    

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllIngresos(
            loggedDashboardJSON,
            currentPage - 1,
            formatDateForString(fechaInicioFilter),
            formatDateForString(fechaFinFilter),
            docClienteFilter,
            razonClienteFilter,
            usuarioFilter,
            pagadorSelected!=null?pagadorSelected.id:null,
            usuariosSeleccionados ? usuariosSeleccionados.map(usuario => usuario.nombre) : null
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
    }, [currentPage, activateFilters]);

    useEffect(() => {
        verificarSucursales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const verificarSucursales = () => {
        const sucursalesData = window.localStorage.getItem("sucursales");
        if(sucursalesData) {
            const sucursales = JSON.parse(sucursalesData)
            if(sucursales.length === 6) {
                setMostrarFiltroUsuarios(true)
            }
        }
    }

    useEffect(() => {
        findAllUsuarios()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findAllUsuarios = async () => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        try {
            const responseData = await UsuarioServices.getUsuariosSimple(loggedDashboardJSON)
            setUsuarios(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };


    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllIngresos = async (token ,page, fechaInicio, fechaFin, rucCliente, razonCliente, usuario, pagador, usuarios ) => {
        setIsDataWaiting(true);
        try {
            const responseData = await IngresosService.getIngresos (true,token, page, fechaInicio, fechaFin, rucCliente, razonCliente, usuario, pagador, usuarios);
            setIngresosData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
            if (responseData !== null && (fechaInicioFilter!='' || fechaFinFilter!='' || docClienteFilter!='' || razonClienteFilter!='' || usuarioFilter !='')){
                setDisabledReporte(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };


    // FILTRO PAAGADPR
    
    const renderAutocompleteFilter = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }
    const handlePagadorSelectedChange = (event, newValue) => {
        setPagadorSelected(newValue ? newValue : null)
    }

    const handleInputPagadorChange = (event, newValue) => {
        setInputPagador(newValue)
    }

    function handlePagadorLabelNombre(objeto) {
        return objeto.nombre
    }
    const autoCompleteObjectComparator = (option, value) => option.id === value.id

    const renderPagadorFilter = () => {
        return (
            <Grid item xs={12} sm={12} md={3} lg={3} style={{  }}>
                {renderAutocompleteFilter(
                    'pagador-filter',
                    pagadorSelected,
                    handlePagadorSelectedChange,
                    inputPagador,
                    handleInputPagadorChange,
                    pagador,
                    handlePagadorLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    100,
                    'Pagador',
                    'small'
                )}
            </Grid>
        )
    }

    // FILTRO FECHA INICIO
    const handleFechaInicioFilter = (date) => {
        setFechaInicioFilter(date)
        setDisabledFecha(false)
    }

    const renderFechaInicioFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-inicio-filter"
                    label="Fecha Inicio"
                    selectedValue={fechaInicioFilter}
                    onChange={handleFechaInicioFilter}>
                </DateSelector>
            </Grid>
        )
    }

    // FILTRO FECHA FIN
    const handleFechaFinFilter = (date) => {
        setFechaFinFilter(date)
    }

    const renderFechaFinFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2}  style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-fin-filter"
                    label="Fecha Fin"
                    selectedValue={fechaFinFilter}
                    disabled={disabledFecha}
                    onChange={handleFechaFinFilter}>
                </DateSelector>
            </Grid>
        )
    }

    //FILTRO RUC USUARIO
    const renderUsuarioFilter = () => {
        return (
            <Grid item xs={12} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="usuario-filter"
                    label="Cobrador"
                    variant="outlined"
                    value={usuarioFilter}
                    onChange={(event) => setUsuarioFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    //FILTRO RUC CLIENTE
    const renderClienteFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="cliente-filter"
                    label="Doc. Cliente"
                    variant="outlined"
                    value={docClienteFilter}
                    onChange={(event) => setDocClienteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderRazonClienteFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="razon-cliente-filter"
                    label="Razon Soc. Cliente"
                    variant="outlined"
                    value={razonClienteFilter}
                    onChange={(event) => setRazonClienteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const handleClearFilters = () => {
        setDisabledReporte(true)
        setFechaInicioFilter('')
        setFechaFinFilter('')
        setDocClienteFilter('')
        setRazonClienteFilter('')
        setUsuarioFilter('')
        setUsuariosSeleccionados([])
        setActivateFilters(!activateFilters)
        setDisabledFecha(true)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={12} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id

    const renderSelectUsuarios = () => {
        return (
            <Autocomplete
                multiple
                size='small'
                id="checkboxes-usuarios"
                options={usuarios}
                value={usuariosSeleccionados}
                isOptionEqualToValue={autoCompleteObjectComparatorById}
                disableCloseOnSelect
                getOptionLabel={(option) => option.nombre}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps}>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                            {option.nombre}
                        </li>
                    );
                }}
                onChange={(event, newValue) => {
                    setUsuariosSeleccionados(newValue);
                }}
                style={{}}
                renderInput={(params) => (
                    <TextField {...params} label="Usuarios" placeholder="Usuarios" />
                )}
            />
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{}}>
                                {renderPagadorFilter()}
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    {mostrarFiltroUsuarios ? renderSelectUsuarios() : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        {renderFechaInicioFilter()}
                        {renderFechaFinFilter()}
                        {renderUsuarioFilter()}
                        {renderClienteFilter()}
                        {renderRazonClienteFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'columnCobrador', align: 'center', label: 'Cobrador', minWidth: 75, format: 'string' },
        { id: 'columnUsuarioRegistro', align: 'center', label: 'Usuario Registro', minWidth: 75, format: 'string' },
        { id: 'columnFechaRegistro', align: 'center', label: 'Fecha Registro', minWidth: 75, format: 'string' },
        { id: 'colummnRucCliente', align: 'center', label: 'R.U.C. Cliente', minWidth: 75, format: 'string' },
        { id: 'colummnRazonCliente', align: 'center', label: 'Razon Social Cliente', minWidth: 75, format: 'string' },
        { id: 'columnGuia', align: 'center', label: 'Guia Transp.', minWidth: 75, format: 'string' },
        { id: 'columnMonto', align: 'center', label: 'Monto', minWidth: 75, format: 'string' },
    ];

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig ? null : renderPagination()}
                </Paper>
            </Grid>
        )
    }


    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        {isDataWaitig ? <LinearProgress color="success" /> : null}
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(ingresosData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (ingresosData.map(ingreso => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${ingreso.id}`}>
                                            <TableCell align='center'>
                                                {ingreso.cobrador}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {ingreso.usuario}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {ingreso.fechaRegistro}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {ingreso.ruc}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {ingreso.razonSocial}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {ingreso.serie}-{ingreso.codigo}
                                            </TableCell>
                                            <TableCell align='center'>
                                                S/.{ingreso.monto}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    
    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig ? <LinearProgress color="success" /> : null}
                <Table>
                    {(ingresosData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (ingresosData.map(ingreso => (
                            <TableBody key={`${ingreso.idGuiaTransportista}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Cobrador:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{ingreso.cobrador}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Usuario Registro:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{ingreso.usuario}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Fecha Registro:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{ingreso.fechaRegistro}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>RUC Cliente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{ingreso.ruc}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Razon Social Cliente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{ingreso.razonSocial}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Guia Transp.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {ingreso.serie}-{ingreso.codigo}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Monto:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> S/.{ingreso.monto} </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }
    
    const handleIngresos = async () => {
        try {
            setIsFinished(2);
            const responseData =  await IngresosService.getIngresos (false,
                tokenActual,  currentPage - 1, 
                formatDateForString(fechaInicioFilter), formatDateForString(fechaFinFilter), 
                docClienteFilter, razonClienteFilter ,usuarioFilter, pagadorSelected!=null?pagadorSelected.id:null, usuariosSeleccionados ? usuariosSeleccionados.map(usuario => usuario.nombre) : null);
            setIngresosDownload(
                <Button variant="contained" color="success">
                    <PDFGenerator
                        setIsFinished={setIsFinished}
                        documento={<DocumentIngresos data={responseData !== null ? responseData.content : null} />}
                        fileName={"Reporte Ingresos.pdf"}
                    />
                </Button>
            )
            setIsFinished(3);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderReporteObject = () => {
        return (
            <DownloadPdfDialog
                setDisabledReporte = {setDisabledReporte}
                setOpen={setOpeReporteDialog}
                deleteFunction={handleIngresos}
                open={openReporteDialog}
                title={'Descarga de Ingresos'}
                contentMessage=''
                pdfDownload={ingresosDownload}
                isFinished={isFinished}
            />
        )
    }

    const handleAddButton = () => {
        setDisabledReporte(true)
        setIsFinished(1);
        setOpeReporteDialog(true)
    }
    
    const renderReporteButton = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item>
                    <Button disabled={disabledReporte} color="success" onClick={handleAddButton} variant="contained">Descargar reporte</Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {renderReporteButton()}
            {renderReporteObject()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
    );
};

export default Ingresos;