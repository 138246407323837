import React, { useState, useEffect } from "react";
//Imports material-ui
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LockResetIcon from '@mui/icons-material/LockReset';
import UsuarioServices from "../../services/user";
import RolServices from "../../services/roles";

//Vistas
import UsuarioModalEliminar from "./UsuarioModalEliminar";
import UsuarioAddUpdate from "./UsuarioAddUpdate";
import UsuarioModalRefresh from "./UsuarioModalRefresh";

//componentes
import Alert from '../../components/Alert'

import TextField from '@mui/material/TextField';
import UsuarioModalFinalizar from "./UsuarioModalFinalizar";

export default function Usuarios(props) {
    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Usuarios;

    const [tableBody, setTableBody] = useState();
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openRefresh, setOpenRefresh] = useState(false);
    const [openFinalizar, setOpenFinalizar] = useState(false);
    const [usuarioFinalizar, setUsuarioFinalizar] = useState('');
    const [pwdFinalizar, setPwdFinalizar] = useState('');
    const [isCreate, setIsCreate] = useState(true);
    const [titleModal, setTitleModal] = useState("");
    const [componentTableResponsive, setComponentTableResponsive] = useState("");
    const [openAlertOk, setOpenAlertOk] = useState(false);
    const [msjAlertExitoso, setMsjAlertExitoso] = useState('');
    const [severityAlert, setSeverityAlert] = useState('info');

    const [idSelectedUsuario, setIdSelectedUsuario] = useState(0);
    const [rowUsuarioSelected, setRowUsuarioSelected] = useState({});

    const [cantPaginas, setCantPaginas] = useState(0)
    const [page, setPage] = useState(1);

    const [nombreUsuarioFiltro, setNombreUsuarioFiltro] = useState('');
    const [rolFiltro, setRolFiltro] = useState('');
    const [activoFiltro, setActivoFiltro] = useState(true);
    const [menuItemRol, setMenuItemRol] = useState('');
    const [tokenActual, setTokenActual] = useState('');

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    const columns = [
        { id: 'colummnUserName', align: 'center', label: 'Usuario', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Estado', minWidth: 75, format: 'string' },
        { id: 'columnRol', align: 'center', label: 'Rol', minWidth: 75, format: 'string' },
        { id: 'columnSucursales', align: 'center', label: 'Sucursales', minWidth: 75, format: 'string' }
    ];

    let usuarioResponse = null;
    let usuarioError = null;


    async function reloadRoles(token) {

        const rolesCombo = await RolServices.getRolesCombo(token);
        setMenuItemRol(
            rolesCombo.map((r) => {
                return (
                    <MenuItem value={r.id}>{r.nombre}</MenuItem>
                )

            })
        )
    }

    const findAllUsers = async (token, page, usuario, rolId, activo) => {
        try {
            const responseData = await UsuarioServices.getUsuarios(token, page, usuario, rolId, activo);
            usuarioResponse = (responseData);
        }
        catch (error) {
            usuarioError = (error.message);
        }
    };

    async function reloadAllEntidad(token, nroPag, nombreUsuarioInput, rolInput, activoInput) {
        setTableBody('')
        setComponentTableResponsive('')
        await findAllUsers(token, nroPag, nombreUsuarioInput, rolInput, activoInput);
        if (usuarioError === null) {
            let rowsEntidad = usuarioResponse.content;
            let cantPaginas = usuarioResponse.totalPages;
            setCantPaginas(cantPaginas);

            setTableBody(
                <TableBody>
                    {rowsEntidad.map((row) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                <TableCell align="center">
                                    {row.usuario}
                                </TableCell>
                                <TableCell align="center">
                                    {row.activo == true ? "ACTIVO" : "INACTIVO"}
                                </TableCell>
                                <TableCell align="center">
                                    {row.rol?.nombre}
                                </TableCell>
                                <TableCell align="center">
                                    {row.sucursales !== null && row.sucursales.length > 0 ? row.sucursales.map(sucursal => sucursal.nombre).join(', ') : "---"}
                                </TableCell>
                                <TableCell key="options" align="center">
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                                        <ThemeProvider theme={theme}>
                                            {
                                                permisos.includes(3) ?
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <IconButton aria-label="refresh" color="refresh" value={row.id} onClick={() => handleClickOpenRefresh(row.id)}><LockResetIcon fontSize="medium" /></IconButton>
                                                    </Grid> : null
                                            }
                                            {
                                                permisos.includes(3) ?
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <IconButton aria-label="edit" color="warning" value={row} onClick={() => handleClickOpenUpdate(row)}><EditIcon fontSize="medium" /></IconButton>
                                                    </Grid> : null
                                            }
                                            {
                                                permisos.includes(4) ?
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <IconButton aria-label="delete" color="error" value={row.id} onClick={() => handleClickOpenDelete(row.id)}><DeleteIcon fontSize="medium" /></IconButton>
                                                    </Grid> : null
                                            }
                                        </ThemeProvider>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            );


            setComponentTableResponsive(
                <Table>
                    {rowsEntidad.map((row) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>
                                    <div>Usuario: {row.usuario}</div>
                                    <div>Estado: {row.activo == true ? "ACTIVO" : "INACTIVO"}</div>
                                    <div>Rol: {row.rol?.nombre}</div>
                                    <div>
                                        Sucursales: {row.sucursales !== null && row.sucursales.length > 0 ? row.sucursales.map(sucursal => sucursal.nombre).join(', ') : "---"}
                                    </div>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                        <ThemeProvider theme={theme}>
                                            <Grid container style={{ paddingTop: "12px" }}>
                                                {
                                                    permisos.includes(3) ?
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <IconButton aria-label="refresh" color="refresh" value={row.id} onClick={() => handleClickOpenRefresh(row.id)}><LockResetIcon fontSize="medium" /></IconButton>
                                                            </div>
                                                        </Grid> : null
                                                }
                                                {
                                                    permisos.includes(3) ?
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <IconButton aria-label="edit" color="warning" value={row} onClick={() => handleClickOpenUpdate(row)}><EditIcon fontSize="medium" /></IconButton>
                                                            </div>
                                                        </Grid> : null
                                                }
                                                {
                                                    permisos.includes(4) ?
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <div style={{ justifyContent: "center", display: "flex" }}>
                                                                <IconButton aria-label="delete" color="error" value={row.id} onClick={() => handleClickOpenDelete(row.id)}><DeleteIcon fontSize="medium" /></IconButton>
                                                            </div>
                                                        </Grid> : null
                                                }
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </Table>
            )
        }
    }


    const handleClickOpenRefresh = (usuarioId) => {
        setOpenRefresh(true);
        setIdSelectedUsuario(usuarioId);
    };

    const handleClickOpenCreate = () => {
        setTitleModal("Registrar Usuario")
        setOpenCreate(true);
        setIsCreate(true);
        setRowUsuarioSelected({})
    };

    const handleClickOpenUpdate = (data) => {
        setTitleModal("Actualizar Usuario")
        setOpenCreate(true);
        setIsCreate(false);
        setRowUsuarioSelected(data);
    };

    const handleClickOpenDelete = (usuarioId) => {
        setOpenDelete(true);
        setIdSelectedUsuario(usuarioId);
    };

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        reloadRoles(loggedDashboardJSON);
        reloadAllEntidad(loggedDashboardJSON, 0, '', '', '')
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }

    }, []);


    async function aplicarFiltro() {
        reloadAllEntidad(tokenActual, 0, nombreUsuarioFiltro, rolFiltro, activoFiltro);
        setPage(1);
    }

    async function clearFiltro() {
        setActivoFiltro(true)
        setRolFiltro('')
        setNombreUsuarioFiltro('')
        reloadAllEntidad(tokenActual, 0, '', '', '');
        setPage(1);
    }


    const handleChangePagina = (event, value) => {
        reloadAllEntidad(tokenActual, value - 1, nombreUsuarioFiltro, rolFiltro, activoFiltro)
        setPage(value);
    };


    const handleChangeRol = (event) => {
        setRolFiltro(event.target.value);
    };


    const handleChangeEstado = (event) => {
        setActivoFiltro(event.target.value);
    };

    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12}>
                            Filtros
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} style={{ paddingLeft: "30px" }}>
                            <FormControl fullWidth>

                                <TextField
                                    autoComplete="off"
                                    size="small"
                                    margin="dense"
                                    id="nombreUsuarioFiltro"
                                    label="Nombre del usuario"
                                    type="text"
                                    variant="standard"
                                    fullWidth
                                    value={nombreUsuarioFiltro}
                                    onChange={(e) => setNombreUsuarioFiltro(e.target.value.toUpperCase())}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} >
                            <FormControl fullWidth>
                                <InputLabel>Rol</InputLabel>
                                <Select
                                    labelId="rol-label"
                                    id="rol-label"
                                    value={rolFiltro}
                                    label="Rol"
                                    onChange={handleChangeRol}
                                    fullWidth
                                >
                                    {menuItemRol}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} >
                            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "right", display: "flex" }}>
                                    <IconButton aria-label="aplicar" color="primary" onClick={aplicarFiltro} ><ManageSearchIcon /></IconButton>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <IconButton aria-label="aplicar" color="primary" onClick={clearFiltro} ><ClearIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TableContainer sx={{ maxHeight: 450 }}>
                                    <Table stickyHeader aria-label="sticky table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                                <TableCell key="acciones" align="center" style={{ minWidth: "40" }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {tableBody}
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : (
                        <TableContainer>
                            {componentTableResponsive}
                        </TableContainer>
                    )
                    }
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Pagination
                                count={cantPaginas}
                                page={page}
                                variant="outlined"
                                color="primary"
                                onChange={handleChangePagina}
                            />
                        </Grid>
                    </Grid>
                    <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
                        <UsuarioAddUpdate
                            title={titleModal}
                            setOpenCreate={setOpenCreate}
                            setUsuarioFinalizar={setUsuarioFinalizar}
                            setPwdFinalizar={setPwdFinalizar}
                            setOpenFinalizar={setOpenFinalizar}
                            isCreate={isCreate}
                            usuarioData={rowUsuarioSelected}
                            reloadAllEntidad={reloadAllEntidad}
                            menuItemRol={menuItemRol}
                            setMsjAlertExitoso={setMsjAlertExitoso}
                            setSeverityAlert={setSeverityAlert}
                            setOpenAlertOk={setOpenAlertOk}
                        />
                    </Dialog>
                    <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                        <UsuarioModalEliminar
                            setOpenDelete={setOpenDelete}
                            id={idSelectedUsuario}
                            setOpenAlertOk={setOpenAlertOk}
                            setMsjAlertExitoso={setMsjAlertExitoso}
                            setSeverityAlert={setSeverityAlert}
                            reloadAllEntidad={reloadAllEntidad} />
                    </Dialog>

                    <Dialog open={openRefresh} onClose={() => setOpenRefresh(false)}>
                        <UsuarioModalRefresh
                            setOpenRefresh={setOpenRefresh}
                            id={idSelectedUsuario}
                            setUsuarioFinalizar={setUsuarioFinalizar}
                            setPwdFinalizar={setPwdFinalizar}
                            setOpenFinalizar={setOpenFinalizar} />
                    </Dialog>

                    <Dialog open={openFinalizar} onClose={() => setOpenFinalizar(false)}>
                        <UsuarioModalFinalizar
                            setOpenFinalizar={setOpenFinalizar}
                            usuarioFinalizar={usuarioFinalizar}
                            pwdFinalizar={pwdFinalizar}
                        />
                    </Dialog>

                    <Alert openAlert={openAlertOk} setOpenAlert={setOpenAlertOk} mensaje={msjAlertExitoso} severity={severityAlert} />
                </Paper>
            </Grid>
            {
                permisos.includes(2) ?
                    <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: "6px" }}>
                        <Grid item>
                            <Button color="success" onClick={handleClickOpenCreate} variant="contained">Registrar Usuario</Button>
                        </Grid>
                    </Grid> : null
            }
        </Grid>
    );
}