import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CustomizeSnackBar from '../../components/Snackbar';
import CuentasService from '../../services/cuentas';
import GuiaService from '../../services/guia';
import DateSelector from '../../components/DateSelector';
import LinearProgress from '@mui/material/LinearProgress';
import AutoComplete from '../../components/AutoComplete';
import CuentasUpdate from './CuentasUpdate';
import Dialog from '@mui/material/Dialog'
import Checkbox from '@mui/material/Checkbox';
import Pagination from "@mui/material/Pagination";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import DownloadPdfDialog from '../../components/DownloadPdfDialog';
import PDFGenerator from '../../components/PDFGenerator'
import DocumentCuentas from './DocumentCuentas'
import ObjectDialog from '../../components/ObjectDialog';

const Cuentas = (props) => {
    const tipoCliente = [
        {
            id: "SI",
            nombre: "CREDITO"
        },
        {
            id: "NO",
            nombre: "CONTADO"
        }
    ]


    const [cuentasData, setCuentasData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    //FILTROS
    const [tipos, setTipo] = useState(tipoCliente)
    const [tipoSelected, setTipoSelected] = useState({ id: 'SI', nombre: 'CREDITO' });
    const [inputTipo, setInputTipo] = useState('');

    const [fechaInicioFilter, setFechaInicioFilter] = useState('')
    const [fechaFinFilter, setFechaFinFilter] = useState('')
    const [disabledFecha, setDisabledFecha] = useState(true);

    const [destinos, setDestinos] = useState([])
    const [destinoSelected, setDestinoSelected] = useState(null);
    const [inputDestino, setInputDestino] = useState('');
    const [docClienteFilter, setDocClienteFilter] = useState('') //Destinatario
    const [razonClienteFilter, setRazonClienteFilter] = useState('') //Destinatario

    const [activateFilters, setActivateFilters] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [tokenActual, setTokenActual] = useState('');

    const [isDataWaitig, setIsDataWaiting] = useState(true);
    // const [selectedCuenta, setSelectedCuenta] = useState(null)
    // const [openCuentasDialog, setOpenCuentasDialog] = useState(false)

    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Cuentas;

    const [cuentasSelected, setCuentasSelected] = useState([]);

    const [sizePage, setSizePage] = useState(10);
    const [disabledUpdate, setDisabledUpdate] = useState(true);
    const [disabledDownload, setDisabledDownload] = useState(true);

    const [openReporteDialog, setOpeReporteDialog] = useState(false)
    const [cuentasDownload, setCuentasDownload] = useState();
    const [isFinished, setIsFinished] = useState(1);
    const [openSaveButton, setOpenSaveButton] = useState(false)
    const [disabledButtonSave, setDisabledButtonSave] = useState(false);


    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });


    useEffect(() => {
        if(cuentasSelected.length==0){
            setDisabledUpdate(true)
        }else{
            setDisabledUpdate(false)
        }
    }, [cuentasSelected, currentPage, activateFilters, openSaveButton])


    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllDestino(loggedDashboardJSON)
    }, [])

    const findAllDestino = async (token) => {
        try {
            const responseData = await GuiaService.getDestinosCuentas(token);
            setDestinos(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    useEffect(() => {
        setCuentasSelected([]);
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllCuentas(
            loggedDashboardJSON,
            currentPage - 1,
            sizePage,
            tipoSelected != null ? tipoSelected.id : null,
            formatDateForString(fechaInicioFilter),
            formatDateForString(fechaFinFilter),
            docClienteFilter,
            razonClienteFilter,
            destinoSelected != null ? destinoSelected.id : null
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
    }, [currentPage, activateFilters]);

    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllCuentas = async (token, page, sizePage, tipo, fechaInicio, fechaFin, rucDestinatario, razonDestinatario, destino) => {
        setIsDataWaiting(true);
        try {
            const responseData = await CuentasService.getCuentas(true, token, page, sizePage, tipo, fechaInicio, fechaFin, rucDestinatario, razonDestinatario, destino);
            setCuentasData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
            if (responseData !== null && (fechaInicioFilter!='' || fechaFinFilter!='' || destinoSelected != null  || docClienteFilter!='' || razonClienteFilter!='' )){
                setDisabledDownload(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    // FILTRO TIPO

    const renderAutocompleteFilter = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }
    const handleTipoSelectedChange = (event, newValue) => {
        setTipoSelected(newValue ? newValue : null)
    }

    const handleInputTipoChange = (event, newValue) => {
        setInputTipo(newValue)
    }

    function handleTipoLabelNombre(objeto) {
        return objeto.nombre
    }
    const autoCompleteObjectComparator = (option, value) => option.id === value.id

    const renderTipoFilter = () => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <Grid container>
                    <Grid item xs={12} sm={3} md={3} lg={3} style={{ paddingBottom: '0.25rem' }}>
                        {renderAutocompleteFilter(
                            'tipo-filter',
                            tipoSelected,
                            handleTipoSelectedChange,
                            inputTipo,
                            handleInputTipoChange,
                            tipos,
                            handleTipoLabelNombre,
                            autoCompleteObjectComparator,
                            true,
                            100,
                            'Tipo Cliente',
                            'small'
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // FILTRO FECHA INICIO
    const handleFechaInicioFilter = (date) => {
        setFechaInicioFilter(date)
        setDisabledFecha(false)
    }

    const renderFechaInicioFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-inicio-filter"
                    label="Fecha Inicio"
                    selectedValue={fechaInicioFilter}
                    onChange={handleFechaInicioFilter}>
                </DateSelector>
            </Grid>
        )
    }

    // FILTRO FECHA FIN
    const handleFechaFinFilter = (date) => {
        setFechaFinFilter(date)
    }

    const renderFechaFinFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-fin-filter"
                    label="Fecha Fin"
                    selectedValue={fechaFinFilter}
                    disabled={disabledFecha}
                    onChange={handleFechaFinFilter}>
                </DateSelector>
            </Grid>
        )
    }

    // FILTRO DESTINO
    const handleDestinoSelectedChange = (event, newValue) => {
        setDestinoSelected(newValue ? newValue : null)
    }

    const handleInputDestinoChange = (event, newValue) => {
        setInputDestino(newValue)
    }

    function handleDestinoLabelNombre(objeto) {
        return objeto.nombre
    }

    const renderDestinoFilter = () => {
        return (
            <Grid item xs={12} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'destino-filter',
                    destinoSelected,
                    handleDestinoSelectedChange,
                    inputDestino,
                    handleInputDestinoChange,
                    destinos,
                    handleDestinoLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Destino',
                    'small'
                )}
            </Grid>
        )
    }

    //FILTRO RUC CLIENTE

    const renderClienteFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="cliente-filter"
                    label="Nro. Doc. Cliente"
                    variant="outlined"
                    value={docClienteFilter}
                    onChange={(event) => setDocClienteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderClienteRazonFilter = () => {
        return (
            <Grid item xs={6} sm={3} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="clienteRazon-filter"
                    label="Razon Soc. Cliente"
                    variant="outlined"
                    value={razonClienteFilter}
                    onChange={(event) => setRazonClienteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const handleClearFilters = () => {
        setDisabledDownload(true);
        setFechaInicioFilter('')
        setFechaFinFilter('')
        setDocClienteFilter('')
        setRazonClienteFilter('')
        setDestinoSelected(null)
        setDisabledFecha(true)
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={12} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderTipoFilter()}
                        {renderFechaInicioFilter()}
                        {renderFechaFinFilter()}
                        {renderDestinoFilter()}
                        {renderClienteFilter()}
                        {renderClienteRazonFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnFechaEmision', align: 'center', label: 'Fecha Emisión', minWidth: 75, format: 'string' },
        { id: 'columnDestino', align: 'center', label: 'Destino', minWidth: 75, format: 'string' },
        { id: 'columnCliente', align: 'center', label: 'Cliente', minWidth: 75, format: 'string' },
        { id: 'columnGuias', align: 'center', label: 'Guias Transp.', minWidth: 75, format: 'string' },
        { id: 'columnMonto', align: 'center', label: 'Monto', minWidth: 75, format: 'string' },
        { id: 'columnCobrador', align: 'center', label: 'Cobrador', minWidth: 75, format: 'string' },
        { id: 'columnAumento', align: 'center', label: 'Aumento', minWidth: 75, format: 'string' },
        { id: 'columnDescuento', align: 'center', label: 'Descuento', minWidth: 75, format: 'string' },
        { id: 'columnTotal', align: 'center', label: 'Total', minWidth: 75, format: 'string' },
        // { id: 'columnOpciones', align: 'center', label: 'Opciones', minWidth: 75, format: 'string' }
    ];

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig ? null : renderPagination()}
                </Paper>
            </Grid>
        )
    }


    // const handleClickEdit = (cuenta) => {
    //     setSelectedCuenta(cuenta)
    //     setOpenCuentasDialog(true)
    // };


    // const renderActionTableButtons = (cuenta) => {
    //     return (
    //         <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
    //             <ThemeProvider theme={theme}>
    //                 {
    //                     permisos.includes(2) || permisos.includes(3) ?
    //                         <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
    //                             <IconButton aria-label="edit" color="refresh" onClick={() => handleClickEdit(cuenta)}>
    //                                 <PriceCheckIcon fontSize="medium" />
    //                             </IconButton>
    //                         </Grid> : null
    //                 }
    //             </ThemeProvider>
    //         </Grid>
    //     )
    // }

    const handleCheckboxChange = (event, item) => {
        if (event.target.checked) {
            const updatedCuentas = cuentasData.map(cuenta => {
                if (cuenta.idGuiaTransportista === item) {
                    return { ...cuenta, checked: true, cobrador: '', aumento: '', descuento: '', montoActual: cuenta.precioTotal }; // Actualizamos todo
                }
                return cuenta; // Devolvemos la cuenta sin modificar si no coincide el ID
            });
            setCuentasSelected([...cuentasSelected, item]);
            setCuentasData(updatedCuentas);
        } else {
            const updatedCuentas = cuentasData.map(cuenta => {
                if (cuenta.idGuiaTransportista === item) {
                    return { ...cuenta, checked: false, cobrador: '', aumento: '', descuento: '', montoActual: '' }; // Actualizamos todo
                }
                return cuenta; // Devolvemos la cuenta sin modificar si no coincide el ID
            });
            setCuentasSelected(cuentasSelected.filter(selectedItem => selectedItem !== item));
            setCuentasData(updatedCuentas);
        }
    };


    const handleChangeCobrador = (value, guiaId) => {

        const updatedCuenta = cuentasData.map(cuenta => {
            if (cuenta.idGuiaTransportista === guiaId) {
                return { ...cuenta, cobrador: value }; // Actualizamos el cobrador
            }
            return cuenta; // Devolvemos la cuenta sin modificar si no coincide el ID
        });

        // Actualizamos el cobrador
        setCuentasData(updatedCuenta);
    };


    const changeStateOnlyIfDecimalNumber = (newValue, idGuiaTransportista, stateFunction) => {
        if (/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
            stateFunction(newValue, idGuiaTransportista)
        }
    }


    // const handleAumentoBlur = (value) => {
    //     const formattedValue = parseFloat(value === '' ? 0 : value).toFixed(2);
    //     setAumento(formattedValue)
    // };



    const handleChangeAumento = (value, guiaId) => {

        const updatedCuenta = cuentasData.map(cuenta => {
            if (cuenta.idGuiaTransportista === guiaId) {
                const aumentoInterno = parseFloat(value) || 0;
                const descuentoInterno = parseFloat(cuenta.descuento) || 0;
                const totalInterno = (cuenta.precioTotal - descuentoInterno + aumentoInterno).toFixed(2);

                return { ...cuenta, aumento: value, montoActual: totalInterno }; // Actualizamos el aumento
            }
            return cuenta; // Devolvemos la cuenta sin modificar si no coincide el ID
        });

        // Actualizamos el cobrador
        setCuentasData(updatedCuenta);
    };



    const handleChangeDescuento = (value, guiaId) => {

        const updatedCuenta = cuentasData.map(cuenta => {
            if (cuenta.idGuiaTransportista === guiaId) {

                const descuentoInterno = parseFloat(value) || 0;

                if (descuentoInterno <= cuenta.precioTotal) {
                    const aumentoInterno = parseFloat(cuenta.aumento) || 0;
                    const totalInterno = (cuenta.precioTotal - descuentoInterno + aumentoInterno).toFixed(2);
                    return { ...cuenta, descuento: value, montoActual: totalInterno }; // Actualizamos el descuento
                } else {
                    return cuenta;
                }

            }
            return cuenta; // Devolvemos la cuenta sin modificar si no coincide el ID
        });

        // Actualizamos el cobrador
        setCuentasData(updatedCuenta);
    };


    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        {isDataWaitig ? <LinearProgress color="success" /> : null}
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(cuentasData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (cuentasData.map(cuenta => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${cuenta.idGuiaTransportista}`}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="success"
                                                    onChange={(event) => handleCheckboxChange(event, cuenta.idGuiaTransportista)}
                                                />
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.fechaRegistro}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.destino}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.razonSocial}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.serie}-{cuenta.codigo}
                                            </TableCell>
                                            <TableCell align='center'>
                                                S/.{cuenta.precioTotal}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <TextField
                                                    autoComplete="off"
                                                    id="cobrador"
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={cuenta.cobrador}
                                                    disabled={!cuentasSelected.includes(cuenta.idGuiaTransportista)}
                                                    onChange={(event) => handleChangeCobrador(event.target.value, cuenta.idGuiaTransportista)}
                                                >
                                                </TextField>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <TextField
                                                    autoComplete="off"
                                                    id="aumento"
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    disabled={!cuentasSelected.includes(cuenta.idGuiaTransportista)}
                                                    value={cuenta.aumento}
                                                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, cuenta.idGuiaTransportista, handleChangeAumento)}
                                                >
                                                </TextField>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <TextField
                                                    autoComplete="off"
                                                    id="descuento"
                                                    variant="outlined"
                                                    size='small'
                                                    disabled={!cuentasSelected.includes(cuenta.idGuiaTransportista)}
                                                    value={cuenta.descuento}
                                                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, cuenta.idGuiaTransportista, handleChangeDescuento)}
                                                    fullWidth>
                                                </TextField>
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {
                                                    cuentasSelected.includes(cuenta.idGuiaTransportista) ? "S/." : ""
                                                }
                                                {
                                                    cuenta.montoActual
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };


    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig ? <LinearProgress color="success" /> : null}
                <Table size="small">
                    {(cuentasData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (cuentasData.map(cuenta => (
                            <TableBody key={`${cuenta.idGuiaTransportista}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div padding="checkbox">
                                            <Checkbox
                                                color="success"
                                                onChange={(event) => handleCheckboxChange(event, cuenta.idGuiaTransportista)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Fecha Emisión:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.fechaRegistro}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Destino:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.destino}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Cliente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.razonSocial}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Guias Transp.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {cuenta.serie}-{cuenta.codigo}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Monto:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> S/.{cuenta.precioTotal} </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Cobrador:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                <TextField
                                                    autoComplete="off"
                                                    id="cobrador"
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={cuenta.cobrador}
                                                    disabled={!cuentasSelected.includes(cuenta.idGuiaTransportista)}
                                                    onChange={(event) => handleChangeCobrador(event.target.value, cuenta.idGuiaTransportista)}
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', paddingTop: '5px' }}>
                                            <div style={{ flex: '0 0 105px' }}>Aumento:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                <TextField
                                                    autoComplete="off"
                                                    id="aumento"
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    disabled={!cuentasSelected.includes(cuenta.idGuiaTransportista)}
                                                    value={cuenta.aumento}
                                                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, cuenta.idGuiaTransportista, handleChangeAumento)}
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', paddingTop: '5px' }}>
                                            <div style={{ flex: '0 0 105px' }}>Descuento:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                <TextField
                                                    autoComplete="off"
                                                    id="descuento"
                                                    variant="outlined"
                                                    size='small'
                                                    disabled={!cuentasSelected.includes(cuenta.idGuiaTransportista)}
                                                    value={cuenta.descuento}
                                                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, cuenta.idGuiaTransportista, handleChangeDescuento)}
                                                    fullWidth>
                                                </TextField>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', paddingTop: '5px' }}>
                                            <div style={{ flex: '0 0 105px' }}>Total:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {
                                                    cuentasSelected.includes(cuenta.idGuiaTransportista) ? "S/." : ""
                                                }
                                                {
                                                    cuenta.montoActual
                                                }
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    // const renderCreateUpdateDialog = () => {
    //     return (
    //         <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openCuentasDialog} onClose={() => setOpenCuentasDialog(false)}>
    //             <CuentasUpdate
    //                 token={tokenActual}
    //                 selectedCuenta={selectedCuenta}
    //                 setDialogOpen={setOpenCuentasDialog}
    //                 reloadData={setActivateFilters}
    //                 flagForReloadData={activateFilters}
    //                 manageAlert={manageAlert}
    //             >
    //             </CuentasUpdate>
    //         </Dialog>
    //     )
    // }

    const saveCuenta = async (reloadData) => {
        try {
            const response = await CuentasService.create(tokenActual, reloadData)
            manageAlert('success', ('Cuenta actualizada exitosamente'), true)
            setActivateFilters(!activateFilters)
            setOpenSaveButton(false)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
        setDisabledUpdate(false);
    }


    const handleSaveButton = async () => {
        var cuentasUpdateData = []
        var isError = false;
        cuentasData.map(cuenta => {
            if (cuenta.checked) {
                if ('cobrador' in cuenta && cuenta.cobrador != '') {
                    cuentasUpdateData.push({
                        idGuiaTransportista: cuenta.idGuiaTransportista,
                        razonSocial: cuenta.razonSocial,
                        destino: cuenta.destino,
                        montoActual:  parseFloat(cuenta.montoActual),
                        cobrador: cuenta.cobrador
                    })

                } else {
                    isError = true;
                }
            }
        });

        if (isError) {
            manageAlert('warning', 'Falta llenar el cobrador', true)
            setDisabledUpdate(false)
        } else{
            saveCuenta( cuentasUpdateData)
        }
    }

    const renderReporteObject = () => {
        return (
            <DownloadPdfDialog
                setDisabledReporte = {setDisabledDownload}
                setOpen={setOpeReporteDialog}
                deleteFunction={handleCuentasDownload}
                open={openReporteDialog}
                title={'Descarga de Cuentas pendientes'}
                contentMessage=''
                pdfDownload={cuentasDownload}
                isFinished={isFinished}
            />
        )
    }
    const handleCuentasDownload = async () => {
        try {
            setIsFinished(2);
            const responseData =  await CuentasService.getCuentas(false, tokenActual, currentPage - 1, 10, tipoSelected != null ? tipoSelected.id : null,
                formatDateForString(fechaInicioFilter),
                formatDateForString(fechaFinFilter), docClienteFilter,
                razonClienteFilter,
                destinoSelected != null ? destinoSelected.id : null);
            setCuentasDownload(
                <Button variant="contained" color="success">
                    <PDFGenerator
                        setIsFinished={setIsFinished}
                        documento={<DocumentCuentas data={responseData !== null ? responseData.content : null} />}
                        fileName={"Reporte Cuentas.pdf"}
                    />
                </Button>
            )
            setIsFinished(3);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }


    const handleDownloadButton = () => {
        setDisabledDownload(true)
        setIsFinished(1);
        setOpeReporteDialog(true)
    }


    const handleChangeSizePage = (event) => {
        setSizePage(event.target.value)
        handleClearFilters()
    }


    const renderUpdateCuenta = () => {
        return (
            <ObjectDialog
                disabledButtonSave= {disabledButtonSave}
                setDisabledButtonSave = {setDisabledButtonSave}
                setOpen={setOpenSaveButton}
                submitFunction={handleSaveButton}
                open={openSaveButton}
                title={'Actualizar Cuentas'}
                contentMessage={'Posterior a este cambio no existe modificación. ¿Está seguro(a) que desea actualizar la(s) cuenta(s)?'}
            />
        )
    }

    const handleClickOpenUpdate = () => {
        setDisabledUpdate(true)
        setOpenSaveButton(true)
        setDisabledButtonSave(false);
    };



    const renderActionButtons = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item xs={3} sm={2} md={2} >
                    <FormControl variant="filled" size='small' fullWidth>
                        <InputLabel>Nro. Registros</InputLabel>
                        <Select
                            labelId="rol-label"
                            id="size-label"
                            value={sizePage}
                            label="size"
                            onChange={handleChangeSizePage}
                            fullWidth
                        >
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="50">50</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item padding="6px">
                    <Button onClick={handleDownloadButton} disabled={disabledDownload} variant="contained" color="info">Descargar Reporte</Button>
                </Grid>
                <Grid item>
                    <Button onClick={handleClickOpenUpdate} disabled={disabledUpdate} variant="contained" color="success" >Guardar</Button>
                </Grid>
            </Grid>
        )
    }


    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderActionButtons()}
            {renderData()}
            {renderReporteObject()}
            {renderUpdateCuenta()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
    );
};

export default Cuentas;