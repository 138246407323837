import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route , Routes} from 'react-router-dom'
import Dashboard from './modules/Dashboard'
import ThemeLogin from './components/ThemeLogin'

export default function App() {
  const[isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard')
    if(loggedDashboardJSON){
        setIsLogin(true)
    }else{
        setIsLogin(false)
    }
    //setIsLogin(true) // dato estatico para poder ingresar al dashboard, comentar para poder ver pantalla de login
  }, [isLogin]);


  return isLogin?
    <Router>
      <Dashboard setIsLogin={setIsLogin}/>
    </Router>
  :  <ThemeLogin setIsLogin={setIsLogin}/>;
}
