import React, { useState, useEffect, Fragment } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Alert from '../../components/Alert'

import RolServices from "../../services/roles";

export default function RolesViewDetails(props) {

    const [openAlertError, setOpenAlertError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [rolResponse, setRolResponse] = useState(null);

    const handleClose = () => {
        props.setOpenDetails(false);
    };

    const findRolById = async (token, rolId) => {
        try {
            const responseData = await RolServices.getRolById(token, rolId);
            setRolResponse(responseData);
            setErrorMessage(null)
        }
        catch (error) {
            setErrorMessage(error.message);
            setOpenAlertError(true)
        }
    };

    useEffect(() => {
        findRolById(props.token, props.rolId)
    }, [props.token, props.rolId])
    
    return (
        <Fragment>
            <DialogTitle align="center">{props.title}</DialogTitle>
            
            <DialogContent style={{ paddingTop: '1vh' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={15} sm={15} md={15}>
                        {rolResponse && Object.keys(rolResponse.modulos).length > 0 ?
                            Object.keys(rolResponse.modulos).map((superModulo, index, array) => (
                                <div key={superModulo}>
                                    {superModulo}<br />
                                    {rolResponse.modulos[superModulo].map((modulo, subIndex) => (
                                        <div key={modulo.nombre}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{modulo.nombre}
                                            {subIndex === rolResponse.modulos[superModulo].length - 1 && index === array.length - 1 ? <></> : <br />}
                                        </div>
                                    ))}
                                    {index === array.length - 1 ? <></> : <br />}
                                </div>
                            ))
                            :
                            <>No se encuentran módulos asociados</>
                        }
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="success" onClick={() => handleClose()} variant="outlined" >Cerrar</Button>
            </DialogActions>

            <Alert openAlert={openAlertError} setOpenAlert={setOpenAlertError} mensaje={errorMessage} severity="error" />
        </Fragment>
    );
}