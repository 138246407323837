import React, { useState, useEffect } from "react";
//Imports material-ui
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';


//Servicios
import { SubModulo1Service } from "../../services/submodulo1";

//Vistas
import SubModulo1AddUpdate from "./SubModulo1AddUpdate";
import SubModulo1ModalEliminar from "./SubModulo1ModalEliminar";

//componentes
import Alert from '../../components/Alert'

export default function SubModulo1(props) {

    const subModulo1Service = new SubModulo1Service();
    const [tableBody, setTableBody] = useState();
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [titleModal, setTitleModal] = useState("");
    const [componentTableResponsive, setComponentTableResponsive] = useState("");
    const [openAlertOk, setOpenAlertOk] = useState(false);
    const [msjAlertExitoso, setMsjAlertExitoso] = useState('');
    const [severityAlert, setSeverityAlert] = useState('');

    const [subTipoEntidad, setSubTipoEntidad] = useState('');

    const [cantPaginas, setCantPaginas] = useState(0)
    const [page, setPage] = useState(1);
    const [menuItemSubEntidadType, setMenuItemSubEntidadType] = useState('');

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            cart: {
                main: '#673ab7',
            }
        },
    });

    const columns = [
        { id: 'column1', align: 'center', label: 'Columna 1', minWidth: 170, format: 'string' },
        { id: 'column2', align: 'center', label: 'Columna 2', minWidth: 170, format: 'string' },
        { id: 'column3', align: 'center', label: 'Columna 3', minWidth: 170, format: (value) => value.toFixed(2) },
        { id: 'column4', align: 'center', label: 'Columna 4', minWidth: 170, format: (value) => value.toFixed(2) }
    ];


    async function reloadAllEntidad(nroPag) {
        const entidadAll = await subModulo1Service.getAllEntidad(nroPag);
        if (entidadAll.status === 200) {
            var rowsEntidad = await entidadAll.data;
            var cantPaginas = 0;

            setTableBody(
                <TableBody>
                    {rowsEntidad.map((row) => {
                        cantPaginas = Math.ceil(row.total_elements / 10);
                        setCantPaginas(cantPaginas)
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                <TableCell key="column1" align="center">
                                    {row.column1}
                                </TableCell>
                                <TableCell key="column2">
                                    {row.column2}
                                </TableCell>
                                <TableCell key="column3" align="center">
                                    {row.column3}
                                </TableCell>
                                <TableCell key="column4" align="center">
                                    {row.column4}
                                </TableCell>
                                <TableCell key="options">
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                                        <ThemeProvider theme={theme}>
                                            <Grid item xs={4} sm={2} md={4}>
                                                <IconButton aria-label="edit" color="warning" value={row.id} onClick={handleClickOpenUpdate}><EditIcon fontSize="medium" /></IconButton>
                                            </Grid>
                                            <Grid item xs={4} sm={2} md={4}>
                                                <IconButton aria-label="delete" color="error" value={row.id} onClick={handleClickOpenDelete}><DeleteIcon fontSize="medium" /></IconButton>
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            );


            setComponentTableResponsive(
                <Table>
                    {rowsEntidad.map((row) => {
                        cantPaginas = Math.ceil(row.total_elements / 10);
                        setCantPaginas(cantPaginas)
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>
                                    <div>Stock: {row.stock} {row.uni_medida}</div>
                                    <div>Nombre: {row.nombre}</div>
                                    <div>Rango Precio venta: S/ {row.precio_venta_menor} - S/ {row.precio_venta_mayor}</div>
                                    <div>Precio compra: S/ {row.precio_compra}</div>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                        <ThemeProvider theme={theme}>
                                            <Grid container style={{ paddingTop: "12px" }}>
                                                <Grid item xs={4} sm={4} md={4}>
                                                    <div style={{ justifyContent: "center", display: "flex" }}>
                                                        <IconButton aria-label="edit" color="warning" value={row.id} onClick={handleClickOpenUpdate}><EditIcon fontSize="medium" /></IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} sm={4} md={4}>
                                                    <div style={{ justifyContent: "center", display: "flex" }}>
                                                        <IconButton aria-label="delete" color="error" value={row.id} onClick={handleClickOpenDelete}><DeleteIcon fontSize="medium" /></IconButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </TableCell>

                            </TableRow>
                        )
                    })}
                </Table>
            )
        }
    }

    const handleClickOpenCreate = () => {
        setTitleModal("Registrar 'objeto'")
        setOpenCreate(true);
        setIsCreate(true);
    };

    const handleClickOpenUpdate = (event) => {
        setTitleModal("Actualizar 'objeto'")
        setOpenCreate(true);
        setIsCreate(false);
    };

    const handleClickOpenDelete = (event) => {
        setOpenDelete(true);
    };

    useEffect(() => {
        setSubTipoEntidad('')
        reloadAllEntidad(0)
        if (props.mobileOpen == true) {
            props.onDrawerToggle()
        }

    }, []);


    async function aplicarFiltro() {
        reloadAllEntidad(0)
        setPage(1);
    }

    async function clearFiltro() {
        setSubTipoEntidad('')
        reloadAllEntidad(0)
        setPage(1);
    }


    const handleChangePagina = (event, value) => {
        reloadAllEntidad((value - 1) * 10)
        setPage(value);
    };


    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }} style={{ padding: "6px" }}>
                        <Grid item xs={12} sm={12} md={12}>
                            Filtros
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Subtipo</InputLabel>
                                <Select
                                    labelId="subtipo-label"
                                    id="subtipo-label"
                                    value={subTipoEntidad}
                                    label="Subtipo"
                                    fullWidth
                                >
                                    {menuItemSubEntidadType}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} >
                            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                <Grid item>
                                    <IconButton aria-label="aplicar" color="primary" onClick={aplicarFiltro} ><ManageSearchIcon /></IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton aria-label="aplicar" color="primary" onClick={clearFiltro} ><ClearIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TableContainer sx={{ maxHeight: 450 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                                <TableCell key="acciones" align="center" style={{ minWidth: "40" }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {tableBody}
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    ) : (
                        <TableContainer>
                            {componentTableResponsive}
                        </TableContainer>
                    )
                    }
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Pagination
                                count={cantPaginas}
                                page={page}
                                variant="outlined"
                                color="primary"
                                onChange={handleChangePagina}
                            />
                        </Grid>
                    </Grid>
                    <Dialog open={openCreate} onClose={() => setOpenCreate(false)}>
                        <SubModulo1AddUpdate
                            title={titleModal}
                            setOpenCreate={setOpenCreate}
                            setOpenAlertOk={setOpenAlertOk}
                            setMsjAlertExitoso={setMsjAlertExitoso}
                            setSeverityAlert={setSeverityAlert}
                            isCreate={isCreate}
                            id={1}
                            reloadAllEntidad={reloadAllEntidad}
                            menuSubEntidad={menuItemSubEntidadType}
                        />
                    </Dialog>
                    <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                        <SubModulo1ModalEliminar
                            setOpenDelete={setOpenDelete}
                            id={1}
                            setOpenAlertOk={setOpenAlertOk}
                            setMsjAlertExitoso={setMsjAlertExitoso}
                            setSeverityAlert={setSeverityAlert}
                            reloadAllEntidad={reloadAllEntidad} />
                    </Dialog>

                    <Alert openAlert={openAlertOk} setOpenAlert={setOpenAlertOk} mensaje={msjAlertExitoso} severity={severityAlert} />
                </Paper>
            </Grid>
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: "6px" }}>
                <Grid item>
                    <Button onClick={handleClickOpenCreate} variant="contained">Agregar "objeto"</Button>
                </Grid>
            </Grid>
            
        </Grid>
    );
}